
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Stack, Grid } from "@mui/material";
import { Link, NavLink, useHistory } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import ChatIcon from '@mui/icons-material/Chat';
import ConstructionIcon from '@mui/icons-material/Construction';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SavingsIcon from '@mui/icons-material/Savings';
import { Helmet } from "react-helmet";


// local imports
import FooterPage from "./Footer";
import { CommentPage } from "./Comments";
import NewsFeed from "./NewsFeed";

import { MakeCard, MonitorCard, WatchCard } from "../customeCards/HomeCards";

const HomePage = ({ isAuthenticated, publicKey, watchlist }) => {

    const [showCanvas, setShowCanvas] = useState(false);
    const closeCanvas = () => setShowCanvas(false);
    const openCanvas = () => setShowCanvas(true);

    const candlHtml = <span style={{ color: '#0d6efd'}}>CANDL</span>;
    const candle10 = <span style={{ fontSize: '1.2em', color: '#0d6efd'}}>10 CANDL</span>
    const candlLink = <a href="https://solscan.io/token/7e2CmG7GSPpK1w6iDuti5UZzg4hwyNhiGk1sFjKjrskd" target="_blank" rel="noopener noreferrer">CANDL</a>



    return (
        <div className="home-page">
        <Stack spacing={1} sx={{marginBottom: '5px'}}>

            <Stack direction='column' sx={{
                backgroundColor: 'lightgray',
                padding: '3rem'
            }}>
                <div style={{textAlign: 'center', paddingBottom: '100px'}}>
                    <h1 style={{fontWeight: 'bold'}}> Welcome to Watchlist101 </h1>
                    <h3 style={{fontStyle: 'italic'}}> "the social watchlist" </h3>
                </div>
                <div style={{paddingLeft: '50px'}}>

                    <Stack direction="row">
                    <IconButton disabled>
                        <ConstructionIcon fontSize="large" sx={{
                            color: 'blue'
                        }}></ConstructionIcon>
                    </IconButton>
                    <h4 style={{paddingTop: '10px', paddingBottom: '0px'}}>Create private and public watchlists</h4>
                    </Stack>

                    <Stack direction="row">
                    <IconButton disabled>
                        <TrendingUpIcon fontSize="large" sx={{
                            color: 'green'
                        }}></TrendingUpIcon>
                    </IconButton>
                    <h4 style={{paddingTop: '10px', paddingBottom: '0px'}}>Monitor their performance</h4>
                    </Stack>

                    <Stack direction="row">
                    <IconButton disabled>
                        <SavingsIcon fontSize="large" sx={{
                            color: 'orange'
                        }}></SavingsIcon>
                    </IconButton>
                    <h4 style={{paddingTop: '10px', paddingBottom: '0px'}}>Earn rewards</h4>
                    </Stack>

                </div>

            </Stack>

            <Stack direction='row' sx={{
                justifyContent: 'space-around',
                backgroundColor: '#E5E4E2',
                paddingTop: '3rem',
                paddingBottom: '5rem',
            }}>
                <MakeCard />
                <MonitorCard />
                <WatchCard />
            </Stack>

            <Stack sx={{
                background: 'lightgray',
                padding: '5rem'
            }}>
                <h4> Token rewards distribution:</h4><br/>
                <div style={{paddingLeft: "50px", paddingBottom: "50px"}}>
                    <h6> Candle coin {candlLink} is a cryptocurrency token on Solana blockchain. </h6>
                    <h6>Sign up and connect your wallet: {candle10}</h6>
                    <h6>Create a public watchlist: {candle10}</h6>
                    <h6>Public watchlists outperforming <span style={{ fontStyle: 'italic'}}>S&P 500</span> will receive more tokens overtime.</h6>
                </div>
                <div>{ publicKey?
                    null: isAuthenticated ? 
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                    <NavLink to="/connect-wallet">
                        <Button color="primary">Connect Wallet</Button>
                    </NavLink>
                    </div>:
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                    <Link to={{
                        pathname: '/login',
                        state: {
                            fromHome: true,
                        }
                    }}>
                        <Button color="primary">Login & Connect Wallet</Button>
                    </Link>
                    </div>
                    }
                </div>
            </Stack>
            <Stack sx={{
                background: '#E5E4E2',
                padding: '5rem'
            }}>
                <h4>
                    <NavLink to="/leaderboard">
                    LEADERBOARD
                    </NavLink>
                </h4><br/>
                <div style={{paddingLeft: "50px"}}>
                    <h6 style={{lineHeight: 2}}>
                        View public watchlists and their performance compare to the market benchmarks.<br/>
                        Share the watchlists in social media or download a copy.<br/>
                        Each watchlist performance is measured from the time it was saved. 
                    </h6>
                </div>
            </Stack>

            <Stack sx={{
                background: 'lightgray',
                padding: '5rem'
            }}>
                <h4> Available Markets:</h4><br/>
                <div style={{paddingLeft: "50px"}}>
                    <h6>S&P 500 components, Nasdaq 100 components, Dow 30 components, and top 100 cryptocurrencies by market cap.</h6>
                    <h6>S&P 500 sectors components with organized lists.</h6>
                    <h6>Popular, sector, commodity, currency, country, volatility, and index ETFs.</h6>
                </div>
            </Stack>

            <Stack sx={{
                background: '#E5E4E2',
                padding: '5rem'
            }}>
            <Helmet>
            <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
            <div class="elfsight-app-efb56b10-ab97-435b-b340-9655b1b61b88"></div>
            </Stack>

            </Stack>
            <FooterPage/>
            <div>
                <ChatIcon style={{fontSize: "50px", color: "orange", position: "fixed", bottom: "10px", right: "10px"}}
                onClick={ () => { openCanvas() } }
                ></ChatIcon>
            </div>
            <CommentPage
            fullUrl={window.location.href}
            identifier="about"
            showCanvas={showCanvas}
            closeCanvas={closeCanvas}
            ></CommentPage>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    publicKey: state.auth.publicKey,
    watchlist: state.auth.watchlistData,
});

export default connect( mapStateToProps )(HomePage);


