
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, NavLink } from 'react-router-dom';
import axios from "axios";
import { Button, Card, IconButton, Stack } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import {
    Connection,
    clusterApiUrl,
} from "@solana/web3.js";


// locals
import "../styles/connectWallet.css";

const getPhantomProvider = () => {
    if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
            return provider;
        }
    }    
};

const getSolflareProvider = () => {
    if ("solflare" in window) {
        const provider = window.solflare;
        if (provider.isSolflare) {
            return provider;
        }
    }    
};

const candlHtml = <span style={{ fontStyle: 'italic', color: 'blue'}}>CANDL</span>;
const candle10 = <span style={{ fontStyle: 'italic', fontSize: '1.2em', color: 'blue'}}>10 CANDL</span>


const ConnectWallet = ({ user, isAuthenticated, userPublicKey }) => {
    
    // console.log(userPublicKey);
    const phantomProvider = getPhantomProvider();
    const solflareProvider = getSolflareProvider();
    const NETWORK = clusterApiUrl('mainnet-beta');

    const connection = new Connection(NETWORK);
    const [connected, setConnected] = useState(false);
    const [publicKey, setPublicKey] = useState(userPublicKey);

    const getAddress = (provider) => {
        if (!provider) return;
        // try to eagerly connect
        provider.connect({ onlyIfTrusted: true }).catch((err) => {
            // fail silently
            // console.log('failed to connect to provider');
        });
        provider.on("connect", (publicKey) => {
            setPublicKey(publicKey.toBase58());
            setConnected(true);
            console.log("connected 👍");
        });
        provider.on("disconnect", () => {
            setPublicKey(null);
            setConnected(false);
            console.log("disconnected 👏");
        });
        provider.on("accountChanged", (publicKey) => {
            setPublicKey(publicKey.toBase58());
            if (publicKey) {
                console.log("[accountChanged] Switched account to " + publicKey?.toBase58());
            } else {
                console.log("[accountChanged] Switched unknown account");
                // In this case, dapps could not do anything, or,
                // Only re-connecting to the new account if it is trusted
                // provider.connect({ onlyIfTrusted: true }).catch((err) => {
                //   // fail silently
                // });
                // Or, always trying to reconnect
                provider
                .connect()
                .then(() => console.log("[accountChanged] Reconnected successfully"))
                .catch((err) => {
                    console.log("[accountChanged] Failed to re-connect: " + err.message);
                });
            }
        });
        return () => {
            provider.disconnect();
        };
    };

    const API_URL = process.env.REACT_APP_API_URL;
    const url = `${API_URL}/api/publicKeyView/`;
 
    const savePublicKey = async () => {
        const data = {
            userid: user.pk,
            publickey: publicKey,
        };
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        try {
            const res = await axios.post(url, data, config)
            .then(res => {
                console.log(res.data.error)
            })
            .catch(err => {
                console.log(err)
            });

            if (res.data.error === 'key already exists') {
                alert('public key already exists!');
            } else {
                alert('public key saved!');
            }
        } catch (err) {
            console.log('failed to save public key');
        }
    };
    useEffect(() => {
        if (publicKey && publicKey !== userPublicKey) {
            savePublicKey();
            localStorage.setItem('public-key', JSON.stringify(publicKey));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey]);

    const connectPhantom = async () => {
        if (!phantomProvider) {
            window.open("https://phantom.app/", "_blank")
            return;
        } else {
            try {
                await phantomProvider.connect();
                getAddress(phantomProvider);
            } catch (err) {
                alert('Failed to connect to provider. Please try again later.');
            }
        }
    };

    const connectSolflare = async () => {
        if (!solflareProvider) {
            window.open("https://solflare.com", "_blank")
            return;
        } else {
            try {
                await solflareProvider.connect();
                getAddress(solflareProvider);
            } catch (err) {
                alert('Failed to connect to provider. Please try again later.');
            }
        }
    };

    const refreshPage = () => {
        window.location.reload();
    };


    if (userPublicKey || !isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <Stack sx={{ width: '100%'}}>
            <Card sx={{ padding: '20px', fontSize: 'medium', backgroundColor: 'honeydew'}}>
                <p> Watchlist101 rewards users by its native {candlHtml} token. 💰 </p>
                <p> Connect your wallet to participate in token rewards program. 🎉 </p>
                <p> Currently supporting Phantom and Solflare wallets only.</p>
            </Card>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <p> Candle coin {candlHtml} is a cryptocurrency token on Solana blockchain. </p>
                <p>Sign up and connect your wallet: {candle10}</p>
                <p>Create a public watchlist: {candle10}</p>
                <p>Public watchlists outperforming <span style={{ fontStyle: 'italic', fontWeight: '500' }}>S&P 500</span> will receive more tokens overtime.</p>
            </AccordionDetails>
            </Accordion>{ publicKey ? (
                <Stack direction='column' spacing={5} sx={{
                    alignItems: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    backgroundColor: 'honeydew'
                }}>
                    <h6>Your wallet is connected.</h6>
                    <NavLink to="/">
                    <HomeSharpIcon fontSize='large' />
                    </NavLink>
                </Stack>
            ) : (
                <Stack direction='column' spacing={5} sx={{
                    alignItems: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    backgroundColor: 'honeydew'
                }}>
                    <h6>Refresh the page after installing your wallet and click connect again.</h6>
                    <Stack direction='row' spacing={5} sx={{ display: 'flex',
                    justifyContent: 'center',
                    }}>
                        <div className="navigation">
                        <a className="connect-button">Connect Wallet</a>
                        <div className="navigation-content">
                            <Button size="large" onClick={connectPhantom} fullWidth>Phantom</Button>
                            <Button size="large" onClick={connectSolflare} fullWidth>Solflare</Button>
                        </div>
                        </div>
                        <Tooltip title="Refresh Page">
                        <IconButton onClick={ refreshPage }>
                        <RefreshIcon fontSize='large' />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Home">
                        <NavLink to="/">
                        <IconButton>
                        <HomeSharpIcon fontSize='large' />
                        </IconButton>
                        </NavLink>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    userPublicKey: state.auth.publicKey,
})

export default connect(mapStateToProps) (ConnectWallet);

