
import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";

import logo from './logo.svg';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';

// local components
import HomePage from "./pages/HomePage";
import MainManage from "./home/HomeManage";
import MainMonitor from "./home/HomeMonitor";
import UserlistWatch from "./watch/UserlistWatch";
import StockWatch from "./watch/MarketWatch";
import ETFWatch from "./watch/ETFWatch";
import CryptoWatch from "./watch/CryptoWatch";
import MakeFromCharts from "./make/MakeFromCharts";
import MakeFromLists from "./make/MakeFromLists";
import LeaderBoard from "./pages/LeaderBoard";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useContainerDimensions } from "./pages/Utils";
import { setNavbarSize } from "./actions/barSize";
import { PageNotFound } from './pages/PageNotFound';
import { loadWatchlist, loadPublicKey } from "./actions/auth";
import { loadCoins } from "./actions/cmcCoins";
import { loadPublicList } from "./actions/publicList";
import MarketSummary from "./pages/MarketSummary";
import ConnectWallet from "./pages/ConnectWallet";
import AnimatedNavebar from "./AnimatedNavbar";
import LeaderboardSummary from "./pages/LeaderboardSummary";

// css imports
import "./App.css";
import "normalize.css";



// import './App.css';

const menuItemStyle = {
  fontSize: '1.2em',
  fontWeight: 'bold',
  // padding: '0.5em',
  textAlign: 'center',
  width: '100%',
  display: 'block',
  textTransform: 'uppercase',
};

const verMenuItemStyle = {
  display: 'flex',
  justifyContent: 'left',
  paddingLeft: '3rem',
};


const App = ({ isAuthenticated, user, loadWatchlist, loadCoins, loadPublicList, loadPublicKey }) => {


  const navbarRef = useRef();
  const navbarHeight = useContainerDimensions(navbarRef).height;
  
  useEffect(() => { setNavbarSize(navbarHeight) }, [navbarHeight]);
  useEffect(() => { loadWatchlist(user) }, [user]);
  // useEffect(() => { loadCoins() }, []);
  useEffect(() => { loadPublicList() }, []);
  useEffect(() => { loadPublicKey(user) }, [user]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Home menu
  const handleAuthChildClick = () => {
    handleButtonVariant('auth');
    setAnchorElUser(null);
  };

  // handle button variant
  const initialButtonVariant = {
    'brand': 'text',
    'about': 'text',
    'home': 'text',
    'watch': 'text',
    'make': 'text',
    'auth': 'text',
  };

  const [buttonVariant, setButtonVariant] = useState(initialButtonVariant);

  const handleButtonVariant = (event) => {
    let btnTarget = event;
    setButtonVariant({
      ...initialButtonVariant,
      [btnTarget]: 'contained',
    });
  };

  const { duration } = 300;

  return (
    <Router>
    <AppBar position="static" color="info" ref={navbarRef}>
      <Container maxWidth="xl" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <Toolbar disableGutters variant="dense">
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            <NavLink to="/">
              <MenuItem key='home-page' onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">WATCHLIST 101</Typography>
              </MenuItem>
            </NavLink>

            <NavLink to="/market-summary">
              <MenuItem key='market-summary' onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">TODAY</Typography>
              </MenuItem>
            </NavLink>
            <hr/>

            <Typography textAlign="left" sx={{paddingLeft: '1rem'}}>HOME</Typography>
            <NavLink to="/manage">
            <MenuItem key='manage' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">MANAGE</Typography>
            </MenuItem>
            </NavLink>
            
            <NavLink to="/monitor">
            <MenuItem key='monitor' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">MONITOR</Typography>
            </MenuItem>
            </NavLink>
            <hr/>
            
            <Typography textAlign="left" sx={{paddingLeft: '1rem'}}>WATCH</Typography>
            <NavLink to="/watchlist">
            <MenuItem key='watchlist' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">WATCHLIST</Typography>
            </MenuItem>
            </NavLink>

            <NavLink to="/stocks">
            <MenuItem key='stocks' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">STOCKS</Typography>
            </MenuItem>
            </NavLink>

            <NavLink to="/etfs">
            <MenuItem key='etfs' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">ETFs</Typography>
            </MenuItem>
            </NavLink>

            <NavLink to="/crypto">
            <MenuItem key='crypto' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">CRYPTO</Typography>
            </MenuItem>
            </NavLink>
            <hr/>

            <Typography textAlign="left" sx={{paddingLeft: '1rem'}}>MAKE</Typography>
            <NavLink to="/make-from-charts">
            <MenuItem key='make' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">BROWSE CHARTS</Typography>
            </MenuItem>
            </NavLink>

            <NavLink to="/make-from-lists">
            <MenuItem key='make' onClick={handleCloseNavMenu} sx={verMenuItemStyle}>
                <Typography textAlign="center">BROWSE LISTS</Typography>
            </MenuItem>
            </NavLink>
            <hr/>

            <NavLink to="/leaderboard">
            <MenuItem key='leaderboard' onClick={handleCloseNavMenu}>
                <Typography textAlign="center">LEADERBOARD</Typography>
            </MenuItem>
            </NavLink>

            </Menu>
          </Box>
          <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
              <NavLink className='nav-link' to="/">
              <img
              alt="101"
              src={logo}
              width="40"
              height="40"
              className="d-inline-block align-top"
              />
              </NavLink>
          </Typography>

          {/* expanded nav bar */}
          <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
          >
            <NavLink to="/" style={{textDecoration: 'none'}}>
              <Stack direction='row'>
              <Button
                className='nav-link'
                variant='text'
                key='brand'
                onClick={(e) => handleButtonVariant('brand')}
                style={{padding: 0, color: 'white', display: 'block'}}
              >
                <img
                alt="101"
                src={logo}
                width="40"
                height="40"
                className="d-inline-block align-top"
                />
              </Button>
              <Button sx={{ color: 'white', ':hover, :focus': {opacity: 0.7} }}>WATCHLIST101</Button>
              </Stack>
            </NavLink>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}}>
          <AnimatedNavebar duration={duration} />
          </Box>
          

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountCircleIcon fontSize="large"  size='large' sx={{ color: 'white'}} />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >{isAuthenticated ?
              <MenuItem key='logout' onClick={handleAuthChildClick} style={menuItemStyle}>
                <NavLink className='nav-link' to="/logout">
                LOGOUT
                </NavLink>
              </MenuItem> :
  
              <MenuItem key='login' onClick={handleAuthChildClick} style={menuItemStyle}>
                <NavLink className='nav-link' to="/login">
                LOGIN / SIGNUP
              </NavLink>
              </MenuItem>
            }
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <Switch>
      <Route exact path="/"> <HomePage /> </Route>
      <Route path="/market-summary"> <MarketSummary /> </Route>
      <Route path="/manage"><MainManage /></Route>
      <Route path="/monitor"><MainMonitor /></Route>
      <Route path="/watchlist"> <UserlistWatch /> </Route>
      <Route path="/stocks"> <StockWatch /> </Route>
      <Route path="/etfs"> <ETFWatch /> </Route>
      <Route path="/crypto"> <CryptoWatch /> </Route>
      <Route path="/make-from-charts"> <MakeFromCharts /> </Route>
      <Route path="/make-from-lists"> <MakeFromLists /> </Route>
      <Route path="/leaderboard"> <LeaderBoard /> </Route>
      <Route path="/login"> <Login /> </Route>
      <Route path="/logout"> <Logout /> </Route>
      <Route path="/privacy-policy"> <PrivacyPolicy /> </Route>
      <Route path="/terms-and-conditions"> <TermsAndConditions /> </Route>
      <Route path="/connect-wallet"> <ConnectWallet /> </Route>
      <Route path="/leaderboard-summary"> <LeaderboardSummary/> </Route>
      <Route path="*"> <PageNotFound /> </Route>
    </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  }
}

export default connect( mapStateToProps, { loadWatchlist, loadCoins, loadPublicList, loadPublicKey } ) (App);
