
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Offcanvas } from 'react-bootstrap';
import { DiscussionEmbed } from "disqus-react";


// const Comments = ({ fullUrl, identifier }) => {
//   useEffect(() => {
//     const DISQUS_SCRIPT = 'disq_script'
//     const sd = document.getElementById('disqus_script');
//     if (!sd) {
//       var disqus_config = function() {
//         this.page.url = fullUrl
//         this.page.identifier = identifier
//       }

//       const d = document
//       const s = d.createElement('script')
//       s.src = 'https://watchlist101.disqus.com/embed.js'
//       s.id = DISQUS_SCRIPT
//       s.async = true
//       s.setAttribute('data-timestamp', +new Date())

//       d.body.appendChild(s)
//     } else {
//       window.DISQUS.reset({
//         reload: true,
//         config: disqus_config,
//       })
//     }
//   }, [])
//   return <div id="disqus_thread"></div>
// }


export const CommentPage = ({
  fullUrl,
  identifier,
  showCanvas,
  closeCanvas,

 }) => {

  return (
    <div>
        <Offcanvas show={showCanvas} onHide={closeCanvas} style={{width: '600px', overflow: 'auto'}} placement='end'>
            <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>New List Items</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <DiscussionEmbed
              shortname="watchlist101"
              config={{
                url: fullUrl,
                identifier: identifier,
                title: 'Watchlist 101',
                language: 'en',
              }}></DiscussionEmbed>
            </Offcanvas.Body>
        </Offcanvas>
    </div>
  )
}