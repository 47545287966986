
import React,  { useState, useEffect, useRef, forwardRef } from "react";
import { Grid, Snackbar } from "@mui/material";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import MuiAlert from "@mui/material/Alert";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

// custom utils
import { DrawChart } from "../charts/TVChart";
import { SaveUserList } from "../pages/SaveLoadList";
import { TopBar } from "../bars/MakeBars";
import { useWindowDimensions } from "../pages/Utils";

// local json files
import dows from "../data/dow.json";
import nasdaqs from "../data/nasdaq.json";
import sandps from "../data/spy.json";
import sandpSectors from "../data/spysectors.json";
import coins from "../data/coins.json";
import etfs from "../data/etfs_market_cap.json";
import { loadWatchlist } from "../actions/auth";


const MakeFromCharts = ({ isAuthenticated, user, navbarSize, topbarSize, stateWatchlist }) => {

    // console.log(etfs[0])

    let history = useHistory();
    history.push(history.location.pathname);

    const dowItems = dows.map(d => d.Ticker);
    const nasdaqItems = nasdaqs.map(d => d.Ticker);
    const sandpItems = sandps.map(d => d.Ticker);
    const etfItems = etfs.slice(0, 250).map(d => d.Ticker)
    const coinList = coins.slice(0, 250).filter(coin => coin.Stablecoin === false).map(coin => coin.Ticker);

    // handle S&P sectors
    const sectorNames = sandpSectors.map(d => `${d.Name} - ${d.Ticker}`);

    const indexList = ['S&P 500 Components', 'NASDAQ 100 Components', 'DOW 30 Components', 'ETFs', 'Cryptocurrency Coins', ...sectorNames];

    // handle list of index
    const [selectList, setSelectList] = useState('DOW 30 Components');

    const [indexItems, setIndexItems] = useState(dowItems);

    const [symbol, setSymbol] = useState('MMM');

    const handleIndexItems = (e) => {
        let listSelected = e.target.value;

        if (listSelected === 'S&P 500 Components') {
            setIndexItems(sandpItems);
            setSelectList(listSelected);
            setSymbol(sandpItems[0]);
        } else if (listSelected === 'NASDAQ 100 Components') {
            setIndexItems(nasdaqItems);
            setSelectList(listSelected);
            setSymbol(nasdaqItems[0]);
        } else if (listSelected === 'DOW 30 Components') {
            setIndexItems(dowItems);
            setSelectList('DOW 30 Components');
            setSymbol(dowItems[0]);
        } else if (listSelected === 'ETFs') {
            setIndexItems(etfItems);
            setSelectList('ETFs');
            setSymbol(etfItems[0]);
        } else if (listSelected === 'Cryptocurrency Coins') {
            setIndexItems(coinList);
            setSelectList('Cryptocurrency Coins');
            setSymbol(coinList[0]);

        } else if (sectorNames.includes(listSelected)) {
            let tkrlist  = sandpSectors.find(v => v.Name === listSelected.split(' - ')[0])['Comps'].map(v => v.Ticker)
            setIndexItems(tkrlist);
            setSelectList(listSelected);
            setSymbol(tkrlist[0]);
        }
    }
    
    // get user watchlists to check for name duplicates
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        const data = localStorage.getItem('watchlist-data') ? JSON.parse(localStorage.getItem('watchlist-data'))['data'] : [];
        setUserList(data);
    }, []);

    const [listNames, setListNames] = useState(userList.length > 0 ? userList.map(item => item.Name) : []);
    
    useEffect(() => {
        if (stateWatchlist) {
            let temp = stateWatchlist.map(item => item.Name);
            setListNames(temp);
        }
    }, [stateWatchlist]);

    // console.log(listNames);

    // help user make a list
    const [devListItems, setDevListItems] = useState([]);
    const [devlistResults, setDevlistResults] = useState([]);

    // get/set user list from/to local storage
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('dev-user-list'));
        if (data) {
            setDevListItems(data);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('dev-user-list', JSON.stringify(devListItems));
    });

    // add-clear-delete user list
    const handleAdd = (tkr, name) => {
        if (!devListItems.includes(tkr)) {
            const newList = [...devListItems, tkr];
            const newResults = [...devlistResults, { ticker: tkr, name: name }];
            setDevListItems(newList);
            setDevlistResults(newResults);
        } else {
            alert('This ticker is already in the list.');
        }
    };

    const handleClearList = () => {
        if (devListItems.length > 0) {
            var dropAll = window.confirm('Drop all items?')
            if (dropAll) {
                setDevListItems([]);
                setDevlistResults([]);
            }
        } else {
            alert('List is already empty');
        }
    }

    const handleDel = (tkr) => {
        const newList = devListItems.filter(item => item !== tkr);
        setDevListItems([...newList]);

        const newResults = devlistResults.filter(item => item.ticker !== tkr);
        setDevlistResults([...newResults]);
        
        // console.log(newResults);
    }

    const checkListName = (newListName) => {
        if (newListName.length > 0 && newListName.length < 25) {
            if (!listNames.includes(newListName)) {
                return true;
            } else {
                alert('List name already exists');
                return false;
            }
        } else {
            alert('List name must be between 1 and 25 characters');
            return false;
        }
    }

    // save new watchlist
    const [saveSuccess, setSaveSuccess] = useState(false);

    const handleSaveList = (newListName, isPublic) => {
        
        if (devListItems.length > 0) {
            if (isAuthenticated && user) {
                if (checkListName(newListName)) {

                    SaveUserList(newListName, devlistResults, isPublic, user.pk, setSaveSuccess);
                }
            } else {
                alert('Please login to save list.');
            }
        } else {
            alert('Please add items to your list.');
        }
    };

    useEffect( () => {
        if (saveSuccess) {
            alert('Your watchlist was saved successfully.')
            setDevListItems([]);
            setDevlistResults([]);
            setSaveSuccess(false);
            window.location.reload();

        }
    }, [saveSuccess]);


    // Show Alert if user is not logged in
    const [openAlert, setOpenAlert] = useState(false);
    useEffect(() => {
        if (!isAuthenticated) {
            setOpenAlert(true);
        }
    }, []);

    
    // show comment canvas
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState(null);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    // setting interval
    const [interval, setInterval] = useState('D');
    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
    }

    // setting theme
    const [darkMode, setDarkMode] = useState(true);
    const handleThemeChange = () => {
        setDarkMode(!darkMode);
        // console.log(e);
    }

    // get/set interval from/to local storage
    useEffect(() => {
        let localInterval = JSON.parse(localStorage.getItem('user-interval'));
        if (localInterval) {
            setInterval(localInterval);
        }

        let localTheme = JSON.parse(localStorage.getItem('dark-theme'));
        if (localTheme !== null) {
            setDarkMode(localTheme);
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('user-interval', JSON.stringify(interval));
        localStorage.setItem('dark-theme', JSON.stringify(darkMode));
    });


    // setting chart dimensions *** potential to improve ***
    const topHeight = navbarSize + topbarSize + 10
    const chartWidth = useWindowDimensions().width;
    const chartHeight = useWindowDimensions().height;

    return (
        <div>
            <div>
            {!isAuthenticated ?
                <Collapse in={openAlert}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setOpenAlert(false);
                        }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 1 }}
                    severity="info"
                    >
                        You must login to save your watchlist.
                    </Alert>
                </Collapse>: null
            }
            </div>
            <div style={{height: topbarSize}}>
            <TopBar
                interval={ interval }
                darkMode={ darkMode }
                indexList={ indexList }
                selectList={ selectList }
                devListItems={ devListItems }
                handleIntervalChange={ handleIntervalChange }
                handleWatchlist={ handleIndexItems }
                handleAdd={ handleAdd }
                handleDel={ handleDel }
                handleSaveList={ handleSaveList }
                handleClearList={ handleClearList }
                saveSuccess={ saveSuccess }
                topbarSize={ topbarSize }
                handleThemeChange={ handleThemeChange }
            ></TopBar>
            </div>
            <Grid container sx={{
                minHeight: chartHeight - topHeight,
                backgroundColor: '#131722',
            }}>
                <Grid item xs={12}>
                    <DrawChart 
                    width={ chartWidth }
                    height={ chartHeight - topHeight }
                    symbol={ symbol }
                    watchlist={ indexItems }
                    interval={ interval }
                    darkMode={ darkMode }
                    ></DrawChart>
                </Grid>
            </Grid>
            <div>
                <Snackbar
                    open={openSnack}
                    autoHideDuration={4000}
                    onClose={handleSnackClose}
                    message="watchlist saved."
                >
                </Snackbar>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        stateWatchlist: state.auth.watchlistData,
        navbarSize: state.barSize.navbarSize,
        topbarSize: state.barSize.topbarSize,
    }
}
  
export default connect( mapStateToProps, { loadWatchlist } ) (MakeFromCharts);
