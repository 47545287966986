
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// save list to db
export const SaveUserList = (listName, listItems, isPublic, userid, setSaveSuccess) => {

    const symbols = listItems.map(item => item.ticker)
    const names = listItems.map(item => item.name)
    // console.log(names)

    const API_URL = process.env.REACT_APP_API_URL;

    const userInfo = {
        'userid': userid,
        'listname': listName,
        'items': listItems,
        'public': isPublic,
    };
    
    const url = `${API_URL}/api/watchlistView/`;
    axios.post(url, userInfo)
    .then( (res) => {
        // console.log(res);
        setSaveSuccess(true);
    }
    ).catch( (err) => {
        setSaveSuccess(false);
        // console.log(err);
    }
    )

    return null;
};

// load list from db
export const LoadUserList = ( userid ) => {

    const [userList, setUserList] = useState([]);

    const API_URL = process.env.REACT_APP_API_URL;

    const url = `${API_URL}/api/watchlistView/`;

    const getUserList = async() => {
        const res = await axios.get(url, {params: {"userid": userid}})
        .then(
            res => setUserList(res.data.nameList)
        ).catch( err => {
            console.log(err)
        }
        );
    };
    useEffect( () => getUserList(), []);

    // save user list names to local storage
    const userListNames = userList.map( l => l[0] );
    // if (userListNames.length > 0) {
    //     localStorage.setItem('server-user-list', JSON.stringify(userList));
    //     localStorage.setItem('server-user-list-names', JSON.stringify(userListNames));
    // }
    // console.log(localStorage.getItem('user-list-names'));

    return userList;
}

// delete list from db or delet an item from db
export const DeleteUserList = ( listName, userid, setDelSuccess, tkr=null ) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const userInfo = {
        'userid': userid,
        'listname': listName,
        'symbol': tkr,
    };

    const url = `${API_URL}/api/watchlistView/`;
    axios.delete(url, {data: userInfo})
    .then((res) => {
        setDelSuccess(true)
    }
    ).catch( (err) => {
        setDelSuccess(false)
    }
    )
};

// add item to list
export const UpdateUserList = (listName, listItems, isPublic, userid, setAddSuccess) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const userInfo = {
        'userid': userid,
        'listname': listName,
        'items': listItems,
        'public': isPublic,
    };

    const url = `${API_URL}/api/watchlistView/`;
    axios.put(url, userInfo)
    .then( res => {
        setAddSuccess(true);
        // console.log('list was updated.');
    }
    ).catch( err => {
        setAddSuccess(true);
        // console.log('failed to update the list.');
    }
    )

    return null;
};

// add multiple items to list
export const BulkUpdateUserList = async(listName, tkrList, userid, isPublic) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const userInfo = {
        'userid': userid,
        'listname': listName,
        'symbols': tkrList,
        'public': isPublic,
    };

    const url = `${API_URL}/api/watchlistView/`;
    const res = await axios.put(url, userInfo)
    .then(
        console.log('item added')
    ).catch( err => {
        console.log(err)
    }
    )

    return null;
};

// update this on backend
// load list name from db
export const LoadListNames = async( userid ) => {

    const API_URL = process.env.REACT_APP_API_URL;

    // const userid = userid;

    const [listNames, setListNames] = useState([]);

    const url = `${API_URL}/api/listNameView/`;

    const res = await axios.get(url, {params: {"userid": userid}})
    .then(
        res => setListNames(res.data.names)
    ).catch( err => {
        console.log(err)
    }
    )

    return listNames;
}


