
import { Alert, Button, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { NavLink, Redirect, useHistory, useLocation } from 'react-router-dom';

// locals
import Manage from './ManagePage';
import DefaultManage from './DefaultManagePage';

export const MainManage = ({isAuthenticated, userWatchlist}) => {

    let history = useHistory();
    history.push(history.location.pathname);
    // console.log(history.location.pathname)

    const watchlistLength = userWatchlist ? userWatchlist.length : 0;

    if (isAuthenticated) {
        if (watchlistLength > 0) {
            return (
                <div>
                    <Manage />
                </div>
            )
        } else {
            return (
                <div>
                <Alert severity='info'>
                    <h6>
                        You don't have a watchlist to manage.
                        To create a watchlist visit the <span style={{color: 'blue'}}>MAKE</span> tab.
                    </h6>
                </Alert>
                <DefaultManage />
                </div>
            )
        }
    } else {
        if (watchlistLength > 0) {
            return (
                <div>
                <Grid container spacing={1} style={{marginTop: '0px'}}>
                    <Grid item xs={10}>
                    <Alert severity='info'>
                        <h6>
                            Login to manage your watchlist.
                        </h6>
                    </Alert>
                    </Grid>
                    <Grid item xs={2}>
                        <NavLink to="/login" style={{ textDecoration: 'none'}}>
                            <Button variant='outlined' color='primary' fullWidth style={{width: '100%', height: '100%'}}>LOGIN</Button>
                        </NavLink>
                    </Grid>
                </Grid>
                <DefaultManage />
                </div>
            )
        } else {
            return (
                <div>
                <Grid container spacing={1} style={{marginTop: '0px'}}>
                    <Grid item xs={10}>
                    <Alert severity='info'>
                        <h6>
                        You don't have a watchlist to manage.
                        To create a watchlist, login then visit the <span style={{color: 'blue'}}>MAKE</span> tab.
                        </h6>
                    </Alert>
                    </Grid>
                    <Grid item xs={2}>
                        <NavLink to="/login" style={{ textDecoration: 'none'}}>
                            <Button variant='outlined' color='primary' fullWidth style={{width: '100%', height: '100%'}}>LOGIN</Button>
                        </NavLink>
                    </Grid>
                </Grid>
                <DefaultManage />
                </div>
            )
        }
    }
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userWatchlist: state.auth.watchlistData,
});
export default connect( mapStateToProps ) (MainManage);

