
import React,  { useEffect, createRef, useState, Component } from "react";
import { Button, Card, Checkbox, Drawer, Grid, List, ListItem, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import {default as ReactSelect} from 'react-select';
import { createFilter } from 'react-select';
import { FixedSizeList as RWList } from 'react-window';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { matchSorter } from 'match-sorter';


// TODO: need a default index watchlist
import stocks from "../data/stocks.json";
import coins from "../data/coins.json";
import etfs from "../data/etfs_market_cap.json"

const stockList = stocks.map(v => ({ value: v.Ticker, label: v.Name }));
const coinList = coins.map(v => ({ value: v.Ticker, label: v.Name }));
const etfList = etfs.map(v => ({ value: v.Ticker, label: v.Name }))
const options = stockList.concat(coinList).concat(etfList);

const windowHeight = 50;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * windowHeight;

        return (
            <RWList
                height={maxHeight}
                itemCount={children.length}
                itemSize={windowHeight}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </RWList>
        );
    }
}

export const TopBar = ({
    interval = 'D',
    darkMode = true,
    indexList,
    selectList,
    devListItems,
    handleIntervalChange,
    handleWatchlist,
    handleAdd,
    handleDel,
    handleSaveList,
    handleClearList,
    saveSuccess,
    topbarSize,
    handleThemeChange,
}) => {

    const intervalList = ['D', 'W', 'M']

    const [showCanvas, setShowCanvas] = useState(false);
    const closeCanvas = () => {setShowCanvas(false)};
    const openCanvas = () => {setShowCanvas(true)};

    // save user list
    const makeName = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        
        today = mm + '-' + dd + '-' + yyyy;

        return today
    }

    const [listName, setListName] = useState(null);

    const handleListName = (e) => {
        setListName(e.target.value);
        // console.log(e.target.value);
    }

    const handleSave = () => {
        let ln = listName ? listName : makeName();
        handleSaveList(ln, isPublic);
        if (saveSuccess) {
            setListName(null);
        }
    }

    // const [selectedOption, setSelectedOption] = useState('');
    const handleSearchSelect = (e) => {
        if (e) {
            const tkr = e.value;
            const name = e.label;
            handleAdd(tkr, name);    
        }
    }
    
    const [isPublic, setIsPublic] = useState(true);

    const handlePublic = () => {
        setIsPublic(!isPublic)
    }

    const [listOptions, setListOptions] = useState(options)
    
    const customStyles = {
        control: base => ({
            ...base,
            height: topbarSize,
            width: '100%',
            minHeight: 35
        })
    };

    // *** keep this const for testing ***
    const handleClick = () => {
        // setIsPublic(!isPublic);
    }


    return (
        <div>
            <Grid container columnSpacing={1} sx={{paddingTop: '1px'}}>
                <Grid item xs={1}>
                <FormControl variant="filled" size="small" fullWidth>
                <InputLabel id="interval">Interval</InputLabel>
                <Select
                    value={interval}
                    onChange={handleIntervalChange}
                >
                    { intervalList.map ( (item, idx) => {
                        return <MenuItem value={item} key={idx}>{ item }</MenuItem>
                    })}
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel control={
                        <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                    }
                    checked={darkMode}
                    onChange={handleThemeChange}
                    label='Dark'
                    labelPlacement="start"
                    />

                </Grid>
                <Grid xs={1}></Grid>
                <Grid item xs={6}>
                    <Stack direction='row'>
                    <div style={{width: '30vw', height: '50', marginRight: '5px'}}>
                    <ReactSelect
                        components={{ MenuList }}
                        defaultValue={ null }
                        onChange={handleSearchSelect}
                        options={listOptions}
                        isClearable={true}
                        placeholder={'Search by ticker, name'}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        styles={customStyles}
                        getOptionLabel={(option) => `${option.value} : ${option.label}`}
                        onInputChange={ inputValue => {
                            setListOptions(matchSorter(options, inputValue, {keys: ['value', 'label']}).splice(0,25))
                        }}
                    />
                    </div>
                    <Button variant='outlined' onClick={ openCanvas } sx={{
                        height: topbarSize,
                    }}>View List</Button>
                    </Stack>
                </Grid>

                <Grid item xs={3} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <FormControl variant="filled" size="small" fullWidth>
                    <InputLabel id="Market">Market</InputLabel>
                    <Select
                        value={selectList}
                        onChange={handleWatchlist}
                    >
                        { indexList.map ( (item, idx) => {
                            return <MenuItem value={item} key={idx}>{ item }</MenuItem>
                        })}
                    </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div>
                <Drawer
                anchor="right"
                open={showCanvas}
                onClose={closeCanvas}
                PaperProps={{sx: {width: '30%'}}}
                >
                <Card style={{display: 'flex', justifyContent: 'center', padding: '5px'}}>
                    <Button variant="contained" disabled>New List Items</Button>
                </Card>
                <br/>
                <Card style={{
                    maxHeight: '60vh',
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                }}>{ devListItems.length > 0 ?
                    <List>{ devListItems.map( (item, index) => (
                            <ListItem key={index} sx={{justifyContent: 'space-between'}}>
                                { item }
                                <IconButton key={index} onClick={ () => handleDel(item) }>
                                    <DeleteIcon></DeleteIcon>
                                </IconButton>
                            </ListItem>
                        ))
                    }</List>:
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px'}}>
                        <HourglassEmptyIcon fontSize='large' color='primary' />
                    </div>
                }
                </Card>
                <br/>
                <Card style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField id='list-name-textField' label='Enter Watchlist Name' variant='filled' onChange={handleListName} />
                <FormControlLabel control={
                <Checkbox
                    defaultChecked={true}
                    onChange={handlePublic}
                ></Checkbox>

                }
                label='Public'
                />
                </Card>
                <br/>
                <Card style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button variant="outlined" onClick={handleClearList}>CLEAR LIST</Button>
                <Button variant="outlined" onClick={handleSave} >SAVE LIST</Button>
                </Card>

                </Drawer>
            </div>
        </div>
    )
}
