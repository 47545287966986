
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _debounce from 'lodash.debounce';


export const useContainerDimensions = myRef => {

    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    // console.log(myRef);
  
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
};


export const VerifyInputSymbol = (inputSymbol) => {

    let tkr = inputSymbol.toUpperCase();
    if (tkr.length > 0 && tkr.length < 7 && tkr.match(/^[A-Z]+$/)) {
        return tkr;
    } else {
        return null;
    }
}

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// window size
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {

    const handleResize = _debounce( () => setWindowDimensions(getWindowDimensions()), 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}