
const initialState = {
    publicList: localStorage.getItem('public-list') ? JSON.parse(localStorage.getItem('public-list'))['data'] : [],
    isFresh: false,
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case 'LOADING_PUBLIC_LIST_SUCCESS':
            localStorage.setItem('public-list', JSON.stringify({'data': payload}));
            return {
                ...state,
                publicList: payload,
                isFresh: true,
            }
        case 'LOADING_PUBLIC_LIST_FAIL':
            return {
                ...state,
                isFresh: false,
            }
        default:
            return {
                ...state,
            };
    }
}

