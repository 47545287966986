
import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOADING_WATCHLIST_SUCCESS,
    LOADING_WATCHLIST_FAIL,
    LOADING_PUBLIC_KEY_SUCCESS,
    LOADING_PUBLIC_KEY_FAIL,
} from './types';


const API_URL = process.env.REACT_APP_API_URL;

export const checkAuthenticated = () => async dispatch => {
    if (typeof window == 'undefined') {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
    if (localStorage.getItem('access_token')) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
    
        const body = JSON.stringify({ token: localStorage.getItem('access_token') });
    
        try {
            const res = await axios.post(`${API_URL}/dj-rest-auth/token/verify/`, body, config);
    
            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }
    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

export const loadUser = () => async dispatch => {
    if (localStorage.getItem('access_token')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access_token')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${API_URL}/dj-rest-auth/user/`, config);
            // console.log(res.data);

            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
};

export const googleLogin = (access_token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ access_token });

    try {
        const res = await axios.post(`${API_URL}/dj-rest-auth/google/`, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
        dispatch(loadWatchlist(res.data.user));
        // console.log(res.data);
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        });
        // console.log(err);
    }
};


export const facebookLogin = (access_token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ access_token });

    try {
        const res = await axios.post(`${API_URL}/dj-rest-auth/facebook/`, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
        dispatch(loadWatchlist(res.data.user));
        // console.log(res.data);
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        });
        // console.log(err);
    }
};

export const twitterLogin = (access_token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({
        'access_token': '',
        'token_secret': ''

    });

    try {
        const res = await axios.post(`${API_URL}/dj-rest-auth/twitter/`, body, config);
        console.log(res.data);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
        // dispatch(loadWatchlist(res.data.user));
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        });
        console.log(err);
    }
};


export const loadWatchlist = ( user ) => dispatch => {

    const url = `${API_URL}/api/watchlistView/`;

    if (user) {
        axios.get(url, {params: {"userid": user.pk}})
        .then( res => {
            dispatch({
                type: LOADING_WATCHLIST_SUCCESS,
                payload: res.data.savedResults,
            });
            // console.log(res.data.savedResults);
        })
        .catch( err => {
            dispatch({
                type: LOADING_WATCHLIST_FAIL,
            });
            console.log(err);
        })
    }
};


export const loadPublicKey = ( user ) => async dispatch => {
    const url = `${API_URL}/api/publicKeyView/`;
    // console.log(user);

    if (user) {
        try {
            const res = await axios.get(url, {params: {"userid": user.pk}});
            dispatch({
                type: LOADING_PUBLIC_KEY_SUCCESS,
                payload: res.data.publicKey,
            });
            // console.log(res.data.publicKey);
        } catch (err) {
            dispatch({
                type: LOADING_PUBLIC_KEY_FAIL,
            });
        }
    }
}