import React, { Component } from "react"
import Navbar from "./appNavbar"
import NavbarItem from "./appNavbar/NavbarItem"
import { Flipper } from "react-flip-toolkit"
import DropdownContainer from "./dropdownContainer"
import HomeDropdown from "./dropdownContents/HomeDropdown"
import WatchDropdown from "./dropdownContents/WatchDropdown"
import MakeDropdown from "./dropdownContents/MakeDropdown"

import { Stack } from "@mui/material";
import { NavLink } from "react-router-dom"


const navbarConfig = [
  { title: "HOME", dropdown: HomeDropdown },
  { title: "WATCH", dropdown: WatchDropdown },
  { title: "MAKE", dropdown: MakeDropdown }
]

export default class AnimatedNavbar extends Component {
  state = {
    activeIndices: [],
    duration: 300,
  }

  resetDropdownState = i => {
    this.setState({
      activeIndices: typeof i === "number" ? [i] : [],
      animatingOut: false
    })
    delete this.animatingOutTimeout
  }

  onMouseEnter = i => {
    if (i === null) {
      return
    }
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout)
      this.resetDropdownState(i)
      return
    }
    if (this.state.activeIndices[this.state.activeIndices.length - 1] === i)
      return

    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.concat(i),
      animatingOut: false
    }))
  }

  onMouseLeave = () => {
    this.setState({
      animatingOut: true
    })
    this.animatingOutTimeout = setTimeout(
      this.resetDropdownState,
      this.props.duration
    )
  }


  render() {
    const { duration } = this.state.duration
    let CurrentDropdown
    let PrevDropdown
    let direction

    const currentIndex = this.state.activeIndices[
      this.state.activeIndices.length - 1
    ]
    const prevIndex =
      this.state.activeIndices.length > 1 &&
      this.state.activeIndices[this.state.activeIndices.length - 2]

    if (typeof currentIndex === "number")
      CurrentDropdown = navbarConfig[currentIndex].dropdown
    if (typeof prevIndex === "number") {
      PrevDropdown = navbarConfig[prevIndex].dropdown
      direction = currentIndex > prevIndex ? "right" : "left"
    }

    return (
      <Flipper
        flipKey={currentIndex}
        spring={duration === 300 ? "noWobble" : { stiffness: 170, damping: 26 }}
      >
        <Navbar onMouseLeave={this.onMouseLeave}>
          <Stack direction="row">
            <NavLink to="/market-summary" style={{ textDecoration: 'none' }}>
              <NavbarItem key='market-summary' title='TODAY' onMouseEnter={this.onMouseEnter}>
              </NavbarItem>
            </NavLink>
            {navbarConfig.map((n, index) => {
              return (
                <NavbarItem
                  key={n.title}
                  title={n.title}
                  index={index}
                  onMouseEnter={this.onMouseEnter}
                >
                  {currentIndex === index && (
                    <DropdownContainer
                      direction={direction}
                      animatingOut={this.state.animatingOut}
                      duration={duration}
                    >
                      <CurrentDropdown/>
                      {PrevDropdown && <PrevDropdown />}
                    </DropdownContainer>
                  )}
                </NavbarItem>
              )
            })}

            <NavLink to="/leaderboard" style={{ textDecoration: 'none' }}>
              <NavbarItem key='leaderboard' title='LEADERBOARD'
              onMouseEnter={this.onMouseEnter}
              >
              </NavbarItem>
            </NavLink>

          </Stack>
        </Navbar>
      </Flipper>
    )
  }
}
