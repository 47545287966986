
import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Alert, Collapse, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// custom utils
import { DrawChart } from "../charts/TVChart";
import { TopBar } from "../bars/ListWatchBars";
import { useWindowDimensions } from "../pages/Utils";


const UserlistWatch = ({ isAuthenticated, user, navbarSize, topbarSize, userWatchlist, publicWatchlist }) => {

    let history = useHistory();
    history.push(history.location.pathname);

    const [stateWatchlist, setStateWatchlist] = useState([]);

    useEffect( () => {
        if (userWatchlist.length > 0) {
            setStateWatchlist(userWatchlist)
        } else {
            let pl = publicWatchlist.filter(v => v.Name.includes('sample'));
            setStateWatchlist(pl.length > 0 ? pl : publicWatchlist.slice(0,2));
        }
    }, [userWatchlist, publicWatchlist]);

    const [loading, setLoading] = useState(false);
    
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        if (stateWatchlist) {
            setUserList(stateWatchlist);
        }
    }, [stateWatchlist]);

    // watchlist names, watchlist items, selected watchlist name
    const [listNames, setListNames] = useState(['Wish']);
    const [activeListName, setActiveListName] = useState('Wish');
    const [activeListItems, setActiveListItems] = useState(['AAPL', 'MSFT', 'GOOG', 'AMZN', 'FB']);

    const [symbol, setSymbol] = useState('AAPL');

    
    useEffect(() => {
        if (userList.length > 0) {
            let ln = userList.map( l => l.Name );
            let li = userList[0].Results.map( l => l.Symbol );
            setListNames(ln);
            setActiveListName(ln[0]);
            setActiveListItems(li);
            setSymbol(li[0]);
        }
    }, [userList]);
    

    const handleWatchlist = (e) => {
        let ln = e.target.value;
        if (userList.length > 0) {
            let li = userList.find(v => v.Name === ln).Results.map( l => l.Symbol );
            setActiveListName(ln);
            setActiveListItems(li);
            setSymbol(li[0]);
        } else {
            alert('This is a default watchlist. To create custome watchlists, visit Make page.');
        }
    }


    // setting interval
    const [interval, setInterval] = useState('D');
    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
    }

    // setting theme
    const [darkMode, setDarkMode] = useState(true);
    const handleThemeChange = () => {
        setDarkMode(!darkMode);
        // console.log(e);
    }


    // get interval from local storage, save interval to local storage
    useEffect(() => {
        let localInterval = JSON.parse(localStorage.getItem('user-interval'));
        if (localInterval) {
            setInterval(localInterval);
        }

        let localTheme = JSON.parse(localStorage.getItem('dark-theme'));
        if (localTheme !== null) {
            setDarkMode(localTheme);
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('user-interval', JSON.stringify(interval));
        localStorage.setItem('dark-theme', JSON.stringify(darkMode));
    });

    // setting chart dimensions *** potential to improve ***
    const topHeight = navbarSize + topbarSize + 10
    const chartWidth = useWindowDimensions().width;
    const chartHeight = useWindowDimensions().height;

    const [openAlert, setOpenAlert] = useState(userWatchlist.length > 0 ? false : true);


    return (
        <div>{ loading ?
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <CircularProgress />
            </div> :
            <div>{ userWatchlist.length > 0 ? <div></div> :
                <div>
                <Collapse in={openAlert}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => { setOpenAlert(false) }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 1 }}
                    severity="info"
                    >
                    <h6>
                        You are viewing default watchlists.
                        To create a custom watchlist visit the <span style={{color: 'blue'}}>MAKE</span> tab.
                    </h6>
                    </Alert>
                </Collapse>

                </div>
                }
                <div>
                    <TopBar
                    interval={ interval }
                    darkMode={ darkMode }
                    indexList={ listNames }
                    selectList={ activeListName }
                    handleIntervalChange={ handleIntervalChange }
                    handleWatchlist={ handleWatchlist }
                    handleThemeChange={ handleThemeChange }
                    ></TopBar>
                </div>
                <Grid container sx={{
                    minHeight: chartHeight - topHeight,
                    backgroundColor: '#131722',
                }}>
                    <Grid item xs={12}>
                        <DrawChart
                        watchlist={ activeListItems }
                        width={ chartWidth }
                        height={ chartHeight - topHeight }
                        symbol={ symbol }
                        interval= { interval}
                        darkMode={ darkMode }
                        ></DrawChart>
                    </Grid>
                </Grid>
            </div>
        }
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    navbarSize: state.barSize.navbarSize,
    topbarSize: state.barSize.topbarSize,
    userWatchlist: state.auth.watchlistData,
    publicWatchlist: state.publicList.publicList,
});

export default connect( mapStateToProps ) (UserlistWatch);

