
import React,  { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { connect } from 'react-redux';

// custom utils
import { DrawChart } from "../charts/TVChart";
import { TopBar } from "../bars/StockWatchBars";
import { useWindowDimensions } from "../pages/Utils";

// local json files
import dows from "../data/dow.json";
import nasdaqs from "../data/nasdaq.json";
import sandps from "../data/spy.json";
import sandpSectors from "../data/spysectors.json";
import coins from "../data/coins.json";

// S&P500 sectors: ['XLI', 'XLV', 'XLK', 'XLC', 'XLY', 'XLU', 'XLF', 'XLB', 'XLRE', 'XLP', 'XLE']

// console.log(window.nvabarHeight);


const StockWatch = ({ navbarSize, topbarSize }) => {

    const dowItems = dows.map(d => d.Ticker);
    const nasdaqItems = nasdaqs.map(d => d.Ticker);
    const sandpItems = sandps.map(d => d.Ticker);
    
    const dropList = ['MIOTAUSD', 'BORAUSD']
    const coinList = coins.slice(0, 110).filter(coin => coin.Stablecoin === false).map(coin => coin.Ticker).filter(coin => !dropList.includes(coin));
    
    
    const sectorNames = sandpSectors.map(d => d.Ticker+' ('+d.Name+')');
    // console.log(sectorNames);

    const indexList = ['S&P500 Components', 'NASDAQ100 Components', 'DOW30 Components', 'Cryptocurrency Coins', ...sectorNames];


    // handle list of index
    const [selectList, setSelectList] = useState('DOW30 Components');

    const [watchlist, setWatchlist] = useState(dowItems);

    const [symbol, setSymbol] = useState('MMM');

    const handleWatchlist = (e) => {
        let listSelected = e.target.value;
        if (listSelected === 'S&P500 Components') {
            setWatchlist(sandpItems);
            setSelectList('S&P500 Components');
            setSymbol(sandpItems[0]);
        } else if (listSelected === 'NASDAQ100 Components') {
            setWatchlist(nasdaqItems);
            setSelectList('NASDAQ100 Components');
            setSymbol(nasdaqItems[0]);
        } else if (listSelected === 'DOW30 Components') {
            setWatchlist(dowItems);
            setSelectList('DOW30 Components');
            setSymbol(dowItems[0]);
        } else if (listSelected === 'Cryptocurrency Coins') {
            setWatchlist(coinList);
            setSelectList('Cryptocurrency Coins');
            setSymbol(coinList[0]);

        } else if (sectorNames.includes(listSelected)) {
            let idx = sectorNames.indexOf(listSelected);
            let tkrList = sandpSectors[idx]['Comps'].map(v => v.Ticker);
            setWatchlist(tkrList);
            setSelectList(listSelected);
            setSymbol(tkrList[0]);
            // console.log(tkrList);
        }
    }

    // setting interval
    const [interval, setInterval] = useState('D');
    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
        // console.log(e);
    }

    // setting theme
    const [darkMode, setDarkMode] = useState(true);
    const handleThemeChange = () => {
        setDarkMode(!darkMode);
        // console.log(e);
    }

    // get interval and theme from local storage, save interval to local storage
    useEffect(() => {
        let localInterval = JSON.parse(localStorage.getItem('user-interval'));
        if (localInterval) {
            setInterval(localInterval);
        }

        let localTheme = JSON.parse(localStorage.getItem('dark-theme'));
        if (localTheme !== null) {
            setDarkMode(localTheme);
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('user-interval', JSON.stringify(interval));
        localStorage.setItem('dark-theme', JSON.stringify(darkMode));
    });

    // setting chart dimensions *** potential to improve ***
    const topHeight = navbarSize + topbarSize + 10
    const chartWidth = useWindowDimensions().width;
    const chartHeight = useWindowDimensions().height;


    return (
        <div>
        <div>
        <TopBar
            interval={ interval }
            darkMode= { darkMode }
            indexList={ indexList }
            selectList={ selectList }
            handleIntervalChange={ handleIntervalChange }
            handleWatchlist={ handleWatchlist }
            handleThemeChange={ handleThemeChange }
        >
        </TopBar>
        </div>

        <Grid container sx={{
            minHeight: chartHeight - topHeight,
            backgroundColor: '#131722',
        }}>
            <Grid item xs={12}>
                <DrawChart
                    width={chartWidth - 10}
                    height={chartHeight - topHeight}
                    symbol={symbol}
                    watchlist={ watchlist }
                    interval={ interval }
                    darkMode={ darkMode }
                ></DrawChart>
            </Grid>
        </Grid>
        </div>
    )
};

const mapStateToProps = (state) => ({
    navbarSize: state.barSize.navbarSize,
    topbarSize: state.barSize.topbarSize,
});

export default connect( mapStateToProps ) (StockWatch);
