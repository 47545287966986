
import React,  { useEffect, createRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { ListGroup, Dropdown, Button, ButtonGroup, Tooltip, ToggleButton, OverlayTrigger } from "react-bootstrap";
import { FacebookShareButton } from "react-share";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const TopBar = ({
    listNames,
    activeList,
    activePeriod,
    handleChart,
    handleListClick,
    handlePeriod,
    tweetText,
    handleChartDownload,
    handleChartCopy,
}) => {

    const periodList = ['1mo', '3mo', '6mo', 'ytd', '1y', '2y', '5y']


    const [radioValue, setRadioValue] = useState('pie')
    const chartTypes = [
        {name: 'LINE', value: 'line'},
        {name: 'PIE', value: 'pie'},
        {name: 'BUBBLE', value: 'bubble'}
    ]

    const handleClick = (e) => {
        setRadioValue(e);
        handleChart(e);
    }


    return (
        <div>
            <Grid container sx={{background: '#C0c0c0'}}>
            <Grid item xs={8}>
                <Stack direction='row' style={{display: 'flex', justifyContent: 'space-between'}}>
                <Dropdown onSelect={ (e) => handleListClick(e) }>
                    <Dropdown.Toggle id="interval-dropdown" size="sm" variant="secondary">
                    Watchlist: { activeList }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>{ listNames.map ( (item, idx) => {
                        return <Dropdown.Item key={idx} eventKey={item}>{item}</Dropdown.Item>
                    })}
                    </Dropdown.Menu>
                </Dropdown>

                <Stack style={{zIndex: '0'}}>
                <ButtonGroup>
                    {chartTypes.map( (radio, idx)=> (
                        <ToggleButton
                        key={idx}
                        id={`chart-${idx}`}
                        type='radio'
                        size='sm'
                        value={radio.value}
                        variant={radioValue === radio.value ? 'primary' : 'outline-primary'}
                        checked={radioValue === radio.value}
                        onChange={ (e) => handleClick(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                </Stack>

                <Dropdown onSelect={ (e) => handlePeriod(e) }>
                    <Dropdown.Toggle id="timeframe-dropdown" size="sm" variant="secondary">
                    Period: { activePeriod }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>{ periodList.map( (item, idx) => {
                        return <Dropdown.Item key={idx} eventKey={item}>{item}</Dropdown.Item>
                    })}</Dropdown.Menu>
                </Dropdown>
                </Stack>              
            </Grid>
            <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Stack direction='row' spacing={1}>

                <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-tt-bottom">tweet this watchlist</Tooltip>}
                >
                {({ ref, ...triggerHandler }) => (
                <Button size="sm" {...triggerHandler} ref={ref} style={{padding: '2px'}} variant='secondary'>
                <a
                className="tweet"
                href={`https://twitter.com/intent/tweet?text=${tweetText}`}
                target="_blank"
                rel="noopener noreferrer"
                > 
                <TwitterIcon sx={{ color: "#ffffff"}} />
                </a>
                </Button>
                )}
                </OverlayTrigger>

                <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-fb-bottom">share this watchlist</Tooltip>}
                >
                {({ ref, ...triggerHandler }) => (
                <Button size="sm" {...triggerHandler} ref={ref} style={{padding: '2px'}} variant='secondary'>

                <FacebookShareButton url='https://watchlist101.com' quote={tweetText}>
                    <FacebookIcon />
                </FacebookShareButton>{' '}
                </Button>
                )}
                </OverlayTrigger>

                <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-download-bottom">download chart</Tooltip>}
                >
                {({ ref, ...triggerHandler }) => (
                <Button size="sm" {...triggerHandler} ref={ref} style={{padding: '2px'}} variant='secondary' onClick={handleChartDownload}>
                <FileDownloadOutlinedIcon/>
                </Button>
                )}
                </OverlayTrigger>

                <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-copy-bottom">copy chart</Tooltip>}
                >
                {({ ref, ...triggerHandler }) => (
                <Button size="sm" {...triggerHandler} ref={ref} style={{padding: '2px'}} variant='secondary' onClick={handleChartCopy}>
                <ContentCopyOutlinedIcon/>
                </Button>
                )}
                </OverlayTrigger>

                </Stack>
            </Grid>
            </Grid>
        </div>
    )
}

export const RightBar = ({
    activeList=[],
    indexList=[],
    radiusList=[],
    chartType='',
}) => {

    if (chartType === 'bubble') {
        return (
            <div>
            <ListGroup>
                <ListGroup.Item variant="primary">
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <span>Ticker</span>
                    <span>% Change</span>
                </div>
                </ListGroup.Item>
                { radiusList.map ( (item, idx) =>
                <ListGroup.Item disabled key={'li'+idx}>
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <span>{ item.Symbol }</span>
                    <span>{ item.Radius }</span>
                </div>
                </ListGroup.Item>
                )            
                }
            </ListGroup>
            </div>
        )    
    }

    return (
        <div>
        <ListGroup>
            <ListGroup.Item variant="primary">
            <div style={{display:'flex', justifyContent: 'space-between'}}>
                <span>Ticker</span>
                <span>Price</span>
            </div>
            </ListGroup.Item>
            { activeList.map ( (item, idx) =>
            <ListGroup.Item disabled key={'li'+idx}>
            <div style={{display:'flex', justifyContent: 'space-between'}}>
                <span>{ item.Symbol }</span>
                <span>{ item.CurrentPrice }</span>
            </div>
            </ListGroup.Item>
            )            
            }
        </ListGroup>
        </div>
    )
}
