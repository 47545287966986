import React from "react"
import { NavLink } from "react-router-dom";
import styled from "styled-components"
import { DropdownSection, HeadingLink } from "./Components"

const WatchDropdownEl = styled.div`
  width: 15rem;
`


const WatchDropdown = () => {
  return (
    <WatchDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ul>
          <HeadingLink>
            <NavLink to="/watchlist">WATCHLIST</NavLink>
          </HeadingLink>
          <HeadingLink>
            <NavLink to="/stocks">STOCKS</NavLink>
          </HeadingLink>
          <HeadingLink>
            <NavLink to="/etfs">ETFs</NavLink>
          </HeadingLink>
          <HeadingLink>
            <NavLink to="/crypto">CRYPTO</NavLink>
          </HeadingLink>
        </ul>
      </DropdownSection>
    </WatchDropdownEl>
  )
};

export default WatchDropdown
