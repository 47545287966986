
import React from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram';
import MailIcon from '@mui/icons-material/Mail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  RedditIcon,
} from "react-share";


// local imports
import { FooterCard } from "../utils/CustomeCards";

const FooterPage = () => {
  return (
    <div className="footer-div">
    <Stack sx={{
      padding: '3rem',
      background: 'rgba(87, 154, 156, 0.295)'
    }}>
      <div style={{
        width: '30hw',
        justifyContent: 'space-between',
        marginBottom: '30px',
        paddingLeft: '8px'
      }}>
          <FacebookShareButton url='https://watchlist101.com' quote="Create and monitor stock watchlists!">
              <FacebookIcon size={32} round={true} />
          </FacebookShareButton>{' '}
          <TwitterShareButton url='https://watchlist101.com' title="Create and monitor stock watchlists!">
              <TwitterIcon size={32} round={true} />
          </TwitterShareButton> {' '}
          <PinterestShareButton url='https://watchlist101.com' media="https://i.postimg.cc/fb1dsxHh/Mobilechart.jpg" description="Create and monitor stock watchlists!">
              <PinterestIcon size={32} round={true} />
          </PinterestShareButton>{' '}
          <RedditShareButton url='https://watchlist101.com' title="Create and monitor stock watchlists!">
              <RedditIcon size={32} round={true} />
          </RedditShareButton>{' '}
          {/* <TelegramShareButton url='https://watchlist101.com' title="Create and monitor stock watchlists!">
              <TelegramIcon size={32} round={true} />
          </TelegramShareButton>{' '}
          <WhatsappShareButton url='https://watchlist101.com' title="Create and monitor stock watchlists!">
              <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton> */}
      </div>

      <Stack direction='row' sx={{
        justifyContent: 'space-between'

      }}>
        <div>
          <div style={{ display: 'flex' }}>
            <IconButton disabled>
             <MailIcon size='small' />
            </IconButton>
          <h6 style={{paddingTop: '10px'}}>chartsmaster1@gmail.com</h6>
          </div>
          <div style={{display: 'flex'}}>
            <IconButton  href="https://t.me/+Y7iZxQrAc1VhOTQx" target='_blank' rel='noopener noreferrer'>
            <TelegramIcon />
            </IconButton>
            <h6 style={{paddingTop: '10px'}}>connect with us on telegram</h6>            
          </div>
        </div>
        <div>
          <div><a href="/terms-and-conditions" >Terms and Conditions</a></div>
          <div><a href="/privacy-policy" >Privacy Policy</a></div>
        </div>
      </Stack>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '25px'
      }}>
            &copy; {new Date().getFullYear()} Copyright: <a href="https://www.watchlist101.com"> watchlist101.com </a>
      </div>

    </Stack>
    </div>
  );
}

export default FooterPage;