
import React, { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Snackbar } from '@mui/material';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Line } from "react-chartjs-2";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const colors = ['#4caf50', '#ffc107', '#f44336', '#2196f3', '#9c27b0',
    '#673ab7', '#3f51b5', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a',
    '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b'];


const LineChart = ({
    listName,
    tkrList,
    startDateList,
    chartRef = null,
    width = "100%",
    height = "80vh",
}) => {

    // console.log(listName);

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(false);
    const [serverResult, setServerResult] = useState({
        dates: [],
        resList: [],
        beError: '',
        failedItems: [],
    });

    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertLevel, setAlertLevel] = useState(0);

    // read market data from backend
    const url = `${API_URL}/api/publicListChartView/`;
    const userInfo = {
        "listName": listName,
        "tkrList": tkrList.join(','),
        "startDateList": startDateList.join(',')
    };
    // console.log(serverResult);

    const fetchData = () => {
        if (tkrList.length > 0) {
            axios.get(url, {params: userInfo})
            .then( res => setServerResult({
                dates: res.data.resList.Date,
                resList: res.data.resList,
                beError: res.data.error,
                failedItems: res.data.resList.failedSymbols,
            }))
            .catch( err => {
                setServerResult({
                    dates: [],
                    resList: [],
                    beError: '',
                    failedItems: tkrList
                });
                setError(err);
                setAlertLevel(1);
            } )
            .finally( () => setLoading(false) );    
        }
    };
    useEffect( () => {
        setLoading(true);
        setAlertLevel(0);
        fetchData();
    }, [tkrList, startDateList]);

    
    // if backend error is not null, set alert level accordingly
    useEffect( () => {
        if (serverResult.beError !== '' && serverResult.failedItems.length === tkrList.length) {
            setAlertLevel(2);
        } else if (serverResult.beError !== '' && serverResult.failedItems.length < tkrList.length) {
            setAlertLevel(3);
        }

    }, [serverResult.beError]);


    // set alert message according to alert level 
    useEffect( () => {
        if (alertLevel === 1) {
            setAlertMsg('Failed to read market data. Please try again later.');
            setShowModal(true);

        } else if (alertLevel === 2) {
            setAlertMsg('Failed to read market data. Please try  again later.');
            setShowModal(true);
        } else if (alertLevel === 3) {
            setAlertMsg('Failed to read market data for ' + serverResult.failedItems.join(', ') );
            setShowModal(true);
        }
    }, [alertLevel]);


    // console.log(error);
    const labels = serverResult.dates
    const options = {
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function(value, index, values) {
                        return labels[index];
                    }
                }
            },
            yAxis: {
                ticks: {
                    display: false,
                },
            }
        },
        responsive: true,
        plugins: {
          legend: {
            position: "top"
          },
          title: {
            display: false,
            text: "Watchlist to market performance comparison"
          },
          datalabels: {
            display: false,
        },
        },
        maintainAspectRatio: false,
    };    
    const chartData = {
    labels,
    datasets: [
        {
            label: `${listName}`,
            data: serverResult.resList[listName] ? serverResult.resList[listName] : [],
            backgroundColor: colors[0],
            borderColor: colors[0],
        },    
        {
            label: "SPY",
            data: serverResult.resList['SPY'] ? serverResult.resList.SPY : [],
            backgroundColor: colors[1],
            borderColor: colors[1],
        },
        {
            label: "QQQ",
            data: serverResult.resList['QQQ'] ? serverResult.resList.QQQ : [],
            backgroundColor: colors[2],
            borderColor: colors[2],
        }
    ]
    };

    const handleClose = () => setShowModal(false);

    return (
        <div>
        { loading?
            <div style={{
                width: width,
                height: height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}><CircularProgress /></div>:
            <div>
                <div style={{
                    width: width,
                    height: height,
                }}>
                    <Line width={width} height={height} options={options} data={chartData} ref={chartRef} />
                </div>
            </div>
        }
        <div>
            <Snackbar
                open={showModal}
                autoHideDuration={5000}
                onClose={() => setShowModal(false)}
                message={alertMsg}
                action={
                    <Fragment>
                    <Button color="secondary" size="small" onClick={() => setShowModal(false)}>
                        DISMISS
                    </Button>
                    </Fragment>
                }
            />
        </div>
        </div>
    );  
}

export default LineChart;