
// get and display user watchlist
import React, { Component, useEffect, useState, useReducer, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Drawer, Grid, List, ListItem, ListItemButton, Tooltip, Stack, Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CSVLink } from "react-csv";
import { FacebookShareButton, FacebookIcon, TwitterIcon } from "react-share";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import Select, { createFilter } from 'react-select';
import { FixedSizeList as RWList } from 'react-window';
import _debounce from 'lodash.debounce';
import { matchSorter } from 'match-sorter';

// local utils
// import { AddItemCanvas } from '../utils/ModalUtil';
import { DeleteUserList, UpdateUserList } from '../pages/SaveLoadList';
import { ServerErrorMessage } from '../pages/PageNotFound';
import { loadWatchlist } from '../actions/auth';
import { useWindowDimensions } from '../pages/Utils';

// local json files
import coins from "../data/coins.json";
import stocks from "../data/stocks.json";
import etfs from "../data/etfs_market_cap.json";


const stockList = stocks.map(v => ({ value: v.Ticker, label: v.Ticker + ' - ' + v.Name }));
const coinList = coins.map(v => ({ value: v.Ticker, label: v.Ticker + ' - ' + v.Name }));
const etfList = etfs.map(v => ({ value: v.Ticker, label: v.Ticker + ' - ' + v.Name }))
const options = stockList.concat(coinList).concat(etfList);

const windowHeight = 35;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * windowHeight;

        return (
            <RWList
                height={maxHeight}
                itemCount={children.length}
                itemSize={windowHeight}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </RWList>
        );
    }
}


const Manage = ({ isAuthenticated, user, stateWatchlist, loadWatchlist, navbarSize }) => {

    const [openList, setOpenList] = useState(stateWatchlist.length > 0 ? stateWatchlist[0] : []);
    const [openlistName, setOpenlistName] = useState(stateWatchlist.length > 0 ? stateWatchlist[0].Name: null);
    const [openlistItems, setOpenlistItems] = useState(stateWatchlist.length > 0 ? stateWatchlist[0].Results : []);
    const [activeButton, setActiveButton] = useState(0);
    const [serverError, setServerError] = useState(openList.length === 0 ? true : false);
    const [loading, setLoading] = useState(false);
    const [listCount, setListCount] = useState(stateWatchlist.length);
    const cardColor = '#f5f5f5';
    
    
    useEffect(() => {
        if (openList.length > 0) {
            setServerError(false);
        }
        setListCount(stateWatchlist.length);
    }, [stateWatchlist]);
    

    const handleListItemClick = (name, index) => {
        // console.log(name, index)
        setOpenList(stateWatchlist[index]);
        setOpenlistName(name);
        setOpenlistItems(stateWatchlist[index].Results);
        setActiveButton(index);
    };

    const handleKeyPress = _debounce( (e) => {

        if (e.keyCode === 40) {
            if (activeButton+1 < listCount) {
                setActiveButton(prev => prev+1)
            } else {
                setActiveButton(0)
            }
        } else if (e.keyCode === 38) {
            if (activeButton > 0) {
                setActiveButton(prev => prev -1)
            } else {
                setActiveButton(listCount-1)
            }
        };

    }, 300);

    useEffect( () => {
        setOpenList(stateWatchlist[activeButton]);
        setOpenlistName(stateWatchlist[activeButton].Name);
        setOpenlistItems(stateWatchlist[activeButton].Results);
    }, [activeButton])


    // delete watchlist from server and update userList state
    const [delSuccess, setDelSuccess] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState(null);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const updateStateAfterDelete = () => {
        // console.log(stateWatchlist.length)
        
        if (stateWatchlist.length > 0 && delSuccess) {
            var newStatelist = stateWatchlist.splice(activeButton, 1)
            setOpenList(newStatelist[0])
            setOpenlistName(newStatelist[0].Name)
            setOpenlistItems(newStatelist[0].Results)
            setActiveButton(0);
            setDelSuccess(false);
            loadWatchlist(user);
        }
    };
    useEffect( () => {
        updateStateAfterDelete();
    }, [delSuccess])


    const handleListDel = () => {
        var dropList = window.confirm(`Are you sure you want to delete ${openlistName}?`);

        if (dropList) {
            try{
                DeleteUserList(openlistName, user.pk, setDelSuccess);
                setDelSuccess(true);
                setSnackMessage('Watchlist was removed successfully.');
                setOpenSnack(true);
                updateStateAfterDelete();

            } catch {
                setOpenSnack(true);
                setSnackMessage('Failed to remove the watchlist. Please refresh the page and try again.');
                setDelSuccess(false);
            }
        }
    };
    

    // add to watchlist
    const [newItems, setNewItems] = useState([]);
    const [addSuccess, setAddSuccess] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x+1, 0);

    const handleAddClick = () => {
        setOpenList(stateWatchlist[activeButton]);
        setOpenlistItems(stateWatchlist[activeButton].Results);
        openCanvas();
    }

    const updateAfterAdd = () => {
        if (addSuccess) {
            loadWatchlist(user);
            setOpenList(stateWatchlist[activeButton]);
            setOpenlistItems(stateWatchlist[activeButton].Results);
            setOpenSnack(true);
            setSnackMessage('Watchlist was updated successfully.');
            forceUpdate();
        }
    };

    useEffect( () => {
        updateAfterAdd();
        setAddSuccess(false);
    }, [addSuccess]);


    const handleUpdateList = () => {
        closeCanvas();
        if (newItems.length > 0) {
            const newItemsRename = newItems.map(v => ({ ticker: v.Symbol, name: v.Name }));
            try {
                UpdateUserList(openlistName, newItemsRename, openList.IsPublic, user.pk, setAddSuccess);
                setNewItems([]); //**needs help */
                updateAfterAdd();
            } catch {
                setAddSuccess(false);
                setOpenSnack(true);
                setSnackMessage('Failed to update watchlist. Please try again.');
            }
        };
    };


    
    const columns = [
        { field: 'symbol', headerName: 'Symbol', width: 100 },
        { field: 'init_price', headerName: 'Initial Price', width: 100 },
        { field: 'curr_price', headerName: 'Current Price', width: 100 },
        { field: 'change', headerName: 'Change $', width: 100 },
        { field: 'percent_change', headerName: '% Change', width: 100 },
        { field: 'init_date', headerName: 'Initial Date', width: 150 },
    ];
    
    const [rows, setRows] = useState([])
    useEffect( () => {
        if (stateWatchlist.length > 0) {
            var wl = stateWatchlist[activeButton] ? stateWatchlist[activeButton] : stateWatchlist[0];
            setRows(wl.Results.map( (item, index) => {
                return {
                    id: index,
                    symbol: item.Symbol,
                    init_price: item.SavedPrice,
                    curr_price: item.CurrentPrice,
                    change: item.Change,
                    percent_change: item.PercentChange,
                    init_date: item.SavedDate,
                }    
            }))
        } else {
            setRows([])
        }
        
    }, [stateWatchlist, openList]);

    const [showCanvas, setShowCanvas] = useState(false);
    const closeCanvas = () => setShowCanvas(false);
    const openCanvas = () => setShowCanvas(true);


    // setting table dimensions *** potential to improve ***
    const mainBoxHeight = useWindowDimensions().height - navbarSize - 15;
    const tableHeight = useWindowDimensions().height - (2*navbarSize) - 15;


    return (
    <div> { loading ?
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
        </div> : serverError ?
        <div>
            <ServerErrorMessage />
        </div> :
        <div>
        <Grid container sx={{marginTop: '10px', height: mainBoxHeight}}>
            <Grid item xs={3} sx={{background: cardColor, height: mainBoxHeight, overflow: 'auto'}}>
                <LeftBar
                    userList={stateWatchlist}
                    activeButton={activeButton}
                    cardColor={cardColor}
                    handleListItemClick={handleListItemClick}
                    panelHeight={mainBoxHeight}
                    handleKeyPress={handleKeyPress}
                ></LeftBar>
            </Grid>

            <Grid item xs={9} sx={{paddingLeft: '10px'}}>
                <ActionBar
                    openList={openList}
                    openlistName={openlistName}
                    openlistItems={openlistItems}
                    handleAddClick={handleAddClick}
                    handleDelClick={handleListDel}
                ></ActionBar>
                <div style={{
                    backgroundColor: cardColor,
                    height: tableHeight,
                    minHeight: tableHeight,
                }}>
                    <DataGrid
                    rows={rows}
                    columns={columns}
                    />
                </div>

            </Grid>
        </Grid>
        <AddItemCanvas
            showCanvas={showCanvas}
            closeCanvas={closeCanvas}
            openCanvas={openCanvas}
            listName={openlistName}
            listItems={openlistItems}
            newItems={newItems}
            setNewItems={setNewItems}
            handleUpdateList={handleUpdateList}
        ></AddItemCanvas>
        <Snackbar
            open={openSnack}
            autoHideDuration={4000}
            onClose={handleSnackClose}
            message={snackMessage}
        ></Snackbar>
        </div>
        }
    </div>
    )
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    stateWatchlist: state.auth.watchlistData,
    navbarSize: state.barSize.navbarSize,
});

export default connect( mapStateToProps, { loadWatchlist } ) (Manage);


function handleTweetMessage(al) {
    var listItems = '$' + al.Results.map(t => t.Symbol).join(', $');
    var tweetMessage = 'Published: ' + al.PublishedDate+ '   Return: $' + al.TotalChange +'      '+ listItems;
    return tweetMessage;    
}


const LeftBar = ({
    userList,
    activeButton,
    cardColor,
    handleListItemClick,
    panelHeight,
    handleKeyPress,

}) => {

    return (
        <div  style={{height: panelHeight, outline: 'none'}} onKeyDown={handleKeyPress} tabIndex='-1'>
            <List dense={true}>
                {userList.map( (v, idx) => (
                    <Fragment key={idx}>
                    <ListItemButton key={idx} 
                    onClick={(e) => handleListItemClick(v.Name, idx)} sx={{backgroundColor: idx === activeButton ? '#bbd9fb' : cardColor}}
                    >
                        <div style={{paddingLeft: '10px'}}>
                            <h6>Name: { v.Name.toUpperCase() }</h6>
                            <h6>Date: { v.PublishedDate}</h6>
                            <h6>Return: $ { v.TotalChange }</h6>
                        </div>
                    </ListItemButton>
                    <hr />
                    </Fragment>
                ))}
            </List>
        </div>
    )    

}


const ActionBar = ({
    openList,
    openlistName,
    openlistItems,
    handleAddClick,
    handleDelClick,
}) => {

    const tweetMessage = handleTweetMessage(openList);
    // console.log(tweetMessage);


    return (
        <Stack direction="row" sx={{background: 'honeydew'}}>
            <Grid item xs={6}>
                <Tooltip title="Add to Watchlist">
                <IconButton
                onClick = { (e) => handleAddClick(openlistName, openlistItems) }
                value={(openlistName, openlistItems)}
                >
                <AddCircleIcon fontSize='large' color='success' />
                </IconButton>
                </Tooltip>

                <Tooltip title="Delete the Watchlist">
                <IconButton onClick = { (e) => handleDelClick(openlistName) } value={openlistName}>
                <DeleteIcon fontSize='large' color='warning' />
                </IconButton>
                </Tooltip>
                <CSVLink
                data={openlistItems.map( (t) => ({Symbol: t.Symbol, Name: t.Name, InitialPrice: t.SavedPrice, CurrentPrice: t.CurrentPrice, Change: t.Change, InitialDate: t.SavedDate}) )}
                filename={openlistName + '.csv'}
                >
                <Tooltip title="Download the Watchlist">
                <IconButton>
                <DownloadForOfflineIcon fontSize='large' color='action' />
                </IconButton>
                </Tooltip>
                </CSVLink>
            </Grid>
            <Grid item xs={6} style={{display: 'flex', justifyContent: 'end'}}>
                <Tooltip title="Share on Twitter">
                <IconButton
                className="tweet"
                href={`https://twitter.com/intent/tweet?text=${tweetMessage}`}
                target="_blank"
                rel="noopener noreferrer"
                >
                <TwitterIcon size={30} round={true} />
                </IconButton>
                </Tooltip>
                <FacebookShareButton url='https://watchlist101.com' quote={tweetMessage}>
                <Tooltip title="Share on Facebook">
                <IconButton>
                    <FacebookIcon size={30} round={true} />
                </IconButton>
                </Tooltip>
                </FacebookShareButton>
            </Grid>
        </Stack>
    )
};


// add item to watchlist from modal
const AddItemCanvas = ({
    showCanvas,
    closeCanvas,
    listName,
    listItems,
    newItems,
    setNewItems,
    handleUpdateList,
}) => {

    const handleSearchSelect = (e) => {
        if (e) {
            const tkr = e.value;
            const name = e.label;
            handleAdd(tkr, name);    
        }
    }


    // add new item(s) to watchlist
    const currentSymbols = listItems.map(v => v.Symbol).concat(newItems.map(v => v.Symbol))
    const handleAdd = (tkr, name) => {
        if (currentSymbols.includes(tkr)) {
            alert('This ticker is already in the list.');
        } else {
            setNewItems([...newItems, {Symbol: tkr, Name: name}])
        }
    };

    const [listOptions, setListOptions] = useState(options)

    return (
        <div>
            <Drawer
                anchor='right'
                open={showCanvas}
                onClose={closeCanvas}
                PaperProps={{
                    sx: {width: '30%'}
                }}
            >
            <Card style={{display: 'flex', justifyContent: 'center', padding: '5px'}}>
                <Button variant="contained" disabled>ADD TO: {listName}</Button>
            </Card>
            <Select
                components={{ MenuList }}
                defaultValue={ null }
                onChange={handleSearchSelect}
                options={listOptions}
                isClearable={true}
                placeholder={'Search by name, ticker, ...'}
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                getOptionLabel={(option) => `${option.value} : ${option.label}`}
                onInputChange={ inputValue => {
                    setListOptions(matchSorter(options, inputValue, {keys: ['value', 'label']}).splice(0,25))
                }}
            />
            <br/>
            <Card style={{
                maxHeight: '60vh',
                overflow: 'auto',
                backgroundColor: '#f5f5f5',
            }}>
                <List>{ listItems.concat(newItems).map( (item, index) => (
                        <ListItem key={index}>{ item.Symbol }</ListItem>
                    ))
                }</List>
            </Card>
            <br/>
            <Card style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="secondary" onClick={handleUpdateList} className="w-100">UPDATE WATCHLIST</Button>
            </Card>
            </Drawer>
        </div>
    );
};

