
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ConstructionIcon from '@mui/icons-material/Construction';

// import makeImg from '../../public/assets/build.jpg'
// import monitorImg from "../assets/monitor.jpg";
// import watchImg from "../assets/candle-chart.jpg";

import { NavLink } from 'react-router-dom';

export function MakeCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image='/assets/build.jpg'
        alt="🔨"
      />
      <CardContent sx={{minHeight: 180}}>
        {/* <Typography gutterBottom variant="h5" component="div">
          Make
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          Create private or public watchlists while browsing charts or 
          popular lists of stocks, etfs, or cryptocurrencies.
        </Typography>
      </CardContent>
      <CardActions>
        <NavLink to='/make-from-charts'>
        <Button size="small">BROWSE CHARTS</Button>
        </NavLink>
        <NavLink to='make-from-lists'>
        <Button size="small">BROWSE LISTS</Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};


export function MonitorCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image="/assets/monitor.jpg"
        alt="green iguana"
      />
      <CardContent sx={{minHeight: 180}}>
        {/* <Typography gutterBottom variant="h5" component="div">
          Manage
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          Add to, delete, download, or share a watchlist. Monitor your watchlist returns since inception
          or its performance compare to popular market benchmarks.
        </Typography>
      </CardContent>
      <CardActions>
        <NavLink to="manage">
        <Button size="small">MANAGE</Button>
        </NavLink>
        <NavLink to="monitor">
        <Button size="small">MONITOR</Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};

export function WatchCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image="/assets/candle-chart.jpg"
        alt="green iguana"
      />
      <CardContent sx={{minHeight: 180}}>
        {/* <Typography gutterBottom variant="h5" component="div">
          Watch
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          Browse your watchlists charts with Tradingview charts. 
          Major indices like S&P 500, Nasdaq, and dow components are also organized into lists and
          available to monitor.
        </Typography>
      </CardContent>
      <CardActions>
        <NavLink to="stocks">
        <Button size="small">STOCKS</Button>
        </NavLink>
        <NavLink to="etfs">
        <Button size="small">ETF</Button>
        </NavLink>
        <NavLink to="crypto">
        <Button size="small">CRYPTO</Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};

