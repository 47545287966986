
import React,  { useState, useEffect, useRef, createRef, Component } from "react";
import { Alert, Box, Button, ButtonGroup, Grid, TextField, Paper, Stack, List, ListItem, ListItemText } from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Select, { createFilter } from 'react-select';
import { FixedSizeList as RWList } from 'react-window';
import { matchSorter } from "match-sorter";

// custom utils
import { SaveUserList } from "../pages/SaveLoadList";
import { loadWatchlist } from "../actions/auth";
import { useWindowDimensions } from "../pages/Utils";

// local json files
import dows from "../data/dow.json";
import nasdaqs from "../data/nasdaq.json";
import sandps from "../data/spy.json";
import coins from "../data/coins.json";
import stocks from "../data/stocks.json";
import etfs from "../data/etfs_market_cap.json";


const stockList = stocks.map(v => ({ value: v.Ticker, label: v.Name }));
const coinList = coins.map(v => ({ value: v.Ticker, label: v.Name }));
const etfList = etfs.map(v => ({ value: v.Ticker, label: v.Name }))
const options = stockList.concat(coinList).concat(etfList);

const windowHeight = 35;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * windowHeight;

        return (
            <RWList
                height={maxHeight}
                itemCount={children.length}
                itemSize={windowHeight}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </RWList>
        );
    }
}

const MakeFromLists = ({ isAuthenticated, user, stateWatchlist, navbarSize, loadWatchlist }) => {

    let history = useHistory();
    history.push(history.location.length);

    const [listOptions, setListOptions] = useState(options)


    // get user watchlists to check for name duplicates
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        const data = localStorage.getItem('watchlist-data') ? JSON.parse(localStorage.getItem('watchlist-data'))['data'] : [];
        setUserList(data);
    }, [stateWatchlist]);

    const [listNames, setListNames] = useState(userList.length > 0 ? userList.map(item => item.Name) : []);
    useEffect(() => {
        if (stateWatchlist) {
            let temp = stateWatchlist.map(item => item.Name);
            setListNames(temp);
        }
    }, [stateWatchlist]);


    // help user make a list
    const popularLists = ['DOW 30', 'NASDAQ 100', 'S&P 500', 'TOP 1000 CRYPTOS', 'TOP 100 ETFs']
    const [activeKey, setActiveKey] = useState(0)
    const [openListItems, setOpenListItems] = useState(dows);

    const [devlistItems, setDevlistItems] = useState([]);
    const [devlistResults, setDevlistResults] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [isPublic, setIsPublic] = useState(true);
    const handlePublic = () => {
        setIsPublic(!isPublic)
    }

    const updateCheckedList = (al) => {
        let alt = al.map(d => d.Ticker)
        let ncl = devlistItems.map( item => alt.indexOf(item));
        setCheckedList([...ncl])
    }    

    // add-clear-delete user list
    const handleAdd = (tkr, name) => {
        if (!devlistItems.includes(tkr)) {
            setDevlistItems([...devlistItems, tkr]);
            setDevlistResults([...devlistResults, { ticker: tkr, name: name }]);
        } else {
            alert('This ticker is already in the list.');
        }
    };

    const handleClearList = () => {
        if (devlistItems.length > 0) {
            var dropAll = window.confirm('Drop all items?')
            if (dropAll) {
                setDevlistItems([]);
                setDevlistResults([]);
                setCheckedList([]);
            }
        } else {
            alert('List is already empty');
        }
    }

    const handleDel = (tkr) => {
        
        let idx = devlistItems.indexOf(tkr)
        setCheckedList([...checkedList.splice(idx, 1)])
        
        const newList = devlistItems.filter(item => item !== tkr);
        setDevlistItems([...newList]);
        
        const newResults = devlistResults.filter(item => item.ticker !== tkr);
        setDevlistResults([...newResults]);
    }


    const makeName = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        
        today = mm + '-' + dd + '-' + yyyy;

        return today
    }


    const checkListName = (newListName) => {
        if (newListName.length > 0 && newListName.length < 25) {
            if (!listNames.includes(newListName)) {
                return true;
            } else {
                alert('List name already exists');
                return false;
            }
        } else {
            alert('List name must be between 1 and 25 characters');
            return false;
        }
    }

    // save watchlist
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [newlistName, setNewlistName] = useState(makeName());

    const handleNewlistName = (e) => {
        setNewlistName(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSaveList()
        }
    }


    const handleSaveList = () => {
        if (devlistItems.length > 0) {
            if (isAuthenticated && user) {
                if (checkListName(newlistName)) {
                    SaveUserList(newlistName, devlistResults, isPublic, user.pk, setSaveSuccess);
                }
            } else {
                alert('Please login to save list.');
            }
        } else {
            alert('Please add items to your list.');
        }
    };
    useEffect( () => {
        if (saveSuccess) {
            alert('Your watchlist was saved successfully.')
            setDevlistItems([]);
            setDevlistResults([]);
            setCheckedList([]);
            setSaveSuccess(false);
            setNewlistName('');
            loadWatchlist(user);
            // window.location.reload();
        }
    }, [saveSuccess]);



    // open/close list
    // *** please fix me!!! ***

    const handleMarketClick = (marketName, marketIndex) => {
        if (marketIndex === 0) {
            // setCheckedList([]);
            setActiveKey(0);
            setOpenListItems(dows);
            // updateCheckedList(dows);
        } else if (marketIndex === 1) {
            // setCheckedList([]);
            setActiveKey(1);
            setOpenListItems(nasdaqs);
            // updateCheckedList(nasdaqs);
        } else if (marketIndex === 2) {
            // setCheckedList([]);
            setActiveKey(2);
            setOpenListItems(sandps);
            // updateCheckedList(sandps);
        } else if (marketIndex === 3) {
            // setCheckedList([]);
            setActiveKey(3);
            setOpenListItems(coins);
            // updateCheckedList(coins);
        } else if (marketIndex === 4) {
            let short_etfs = etfs.slice(0, 100);
            // setCheckedList([]);
            setActiveKey(4);
            setOpenListItems(short_etfs);
            // updateCheckedList(short_etfs);
        }
    };

    const handleAddRemove = (e) => {

        const tkr = e.row.ticker
        const name = e.row.name
        if (devlistItems.includes(tkr)) {
            handleDel(tkr);
        } else {
            handleAdd(tkr, name);
        }
    }

    // table Info
    const columns = [
        { field: 'ticker', headerName: 'Ticker', width: 100 },
        { field: 'name', headerName: 'Name', width: 300 },
    ];
    
    const rows = openListItems.map( (item, index) => {
        return {
            id: index,
            ticker: item.Ticker,
            name: item.Name,
        }
    });

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        )
    }

    const handleSearchSelect = (e) => {
        if (e) {
            const tkr = e.value;
            const name = e.label;
            handleAdd(tkr, name);
        }
    };

    useEffect( () => {

        updateCheckedList(openListItems)

    }, [devlistItems, openListItems]);

    // setting table dimensions *** potential to improve ***
    const mainBoxHeight = useWindowDimensions().height - navbarSize - 15;
    const cardColor = '#f5f5f5'



    return (
        <div>
            <div>
            {!isAuthenticated ?
                <Alert severity="info">
                    You must be logged in to save your watchlist.
                </Alert>: null
            }
            </div>
            <Stack direction='row' spacing={2} sx={{
                marginTop: '10px',
                height: mainBoxHeight
            }}>
                <div style={{backgroundColor: cardColor, overflow: 'auto', width: '15vw'}}>
                    <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                    {popularLists.map( (v, idx) => (
                        <>
                        <Button color='primary' size='large' key={idx}
                        onClick={(e) => handleMarketClick(v, idx)}
                        variant={idx === activeKey ? 'contained' : 'text'}
                        >{v}</Button>
                        <hr/>
                        </>
                    ))
                    }
                    </ButtonGroup>
                </div>
                <div style={{width: '40vw'}}>
                    <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    onCellClick={(e) => handleAddRemove(e)}
                    sx={{
                        backgroundColor: '#f5f5f5',
                    }}
                    onSelectionModelChange={setCheckedList}
                    selectionModel={checkedList}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    checkboxSelection={true}
                    />
                </div>
                <div style={{ width: '45vw', paddingRight: '5px', backgroundColor: '#f5f5f5' }}>
                    <Stack direction='row' sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '30vw'}}>
                        <Select
                            components={{ MenuList }}
                            defaultValue={ null }
                            onChange={handleSearchSelect}
                            options={ listOptions }
                            isClearable={true}
                            placeholder={'Search by name, ticker, ...'}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            getOptionLabel={(option) => `${option.value} : ${option.label}`}
                            onInputChange={ inputValue => {
                                setListOptions(matchSorter(options, inputValue, {keys: ['value', 'label']}).splice(0,25))
                            }}
                        />
                        </div>

                        <FormControlLabel control={
                        <Checkbox
                            defaultChecked={true}
                            onChange={handlePublic}
                        ></Checkbox>
                        }
                        label='Public'
                        />
    
                    </Stack>
                    <Box sx={{padding: '10px', height: mainBoxHeight - 150, overflowY: 'auto'}}>
                        {devlistItems.length === 0 ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}>
                        <HourglassEmptyIcon fontSize='large' color='primary'/>
                        </div> :
                        <List>
                        {devlistResults.map((d, idx) => (
                            <ListItem key={'li'+idx}>
                                <ListItemText primary={d.ticker + ' - ' + d.name} key={'lit'+idx} />
                                <IconButton key={'ib'+idx} onClick={() => handleDel(d.ticker)}>
                                    <DeleteIcon key={'di'+idx} />
                                </IconButton>
                            </ListItem>
                        ))
                        }
                        </List>
                        }
                    </Box>
                    <Stack direction='row' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <TextField id='list-name-textField' label='Enter Watchlist Name' variant='filled'
                            size='small'
                            value={newlistName}
                            onChange={handleNewlistName}
                            onKeyDown={handleKeyPress}
                        />
                        <Button variant='outlined' color='primary' size='small' onClick={handleClearList}>Clear</Button>
                        <Button variant='outlined' color='primary' size='small' onClick={handleSaveList}>Save</Button>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        stateWatchlist: state.auth.watchlistData,
        navbarSize: state.barSize.navbarSize,
    }
}
  
export default connect( mapStateToProps, { loadWatchlist } ) (MakeFromLists);
