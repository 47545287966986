
import React, { useEffect, useState, useRef, createRef } from "react";
import { Grid, Paper, Typography, Stack } from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Helmet } from "react-helmet";
import ChatIcon from "@mui/icons-material/Chat";

// locals
import { CommentPage } from "./Comments";
import { useContainerDimensions } from "./Utils";
import NewsFeed from "./NewsFeed";

const MarketSummary = () => {

    const [showCanvas, setShowCanvas] = useState(false);
    const closeCanvas = () => setShowCanvas(false);
    const openCanvas = () => setShowCanvas(true);

    // market overview 
    const moRef = createRef();
    const moScript = document.createElement("script");
    moScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    moScript.async = true;
    moScript.innerHTML = `
    {
        "colorTheme": "light",
        "dateRange": "12M",
        "showChart": true,
        "locale": "en",
        "largeChartUrl": "",
        "isTransparent": false,
        "showSymbolLogo": true,
        "showFloatingTooltip": false,
        "width": "100%",
        "height": "600",
        "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
        "plotLineColorFalling": "rgba(41, 98, 255, 1)",
        "gridLineColor": "rgba(240, 243, 250, 0)",
        "scaleFontColor": "rgba(120, 123, 134, 1)",
        "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
        "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
        "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
        "tabs": [
          {
            "title": "Indices",
            "symbols": [
              {
                "s": "FOREXCOM:SPXUSD",
                "d": "S&P 500"
              },
              {
                "s": "FOREXCOM:NSXUSD",
                "d": "US 100"
              },
              {
                "s": "FOREXCOM:DJI",
                "d": "Dow 30"
              },
              {
                "s": "INDEX:NKY",
                "d": "Nikkei 225"
              },
              {
                "s": "INDEX:DEU40",
                "d": "DAX Index"
              },
              {
                "s": "FOREXCOM:UKXGBP",
                "d": "UK 100"
              }
            ],
            "originalTitle": "Indices"
          },
          {
            "title": "Futures",
            "symbols": [
              {
                "s": "CME_MINI:ES1!",
                "d": "S&P 500"
              },
              {
                "s": "CME:6E1!",
                "d": "Euro"
              },
              {
                "s": "COMEX:GC1!",
                "d": "Gold"
              },
              {
                "s": "NYMEX:CL1!",
                "d": "Crude Oil"
              },
              {
                "s": "NYMEX:NG1!",
                "d": "Natural Gas"
              },
              {
                "s": "CBOT:ZC1!",
                "d": "Corn"
              }
            ],
            "originalTitle": "Futures"
          },
          {
            "title": "Bonds",
            "symbols": [
              {
                "s": "CME:GE1!",
                "d": "Eurodollar"
              },
              {
                "s": "CBOT:ZB1!",
                "d": "T-Bond"
              },
              {
                "s": "CBOT:UB1!",
                "d": "Ultra T-Bond"
              },
              {
                "s": "EUREX:FGBL1!",
                "d": "Euro Bund"
              },
              {
                "s": "EUREX:FBTP1!",
                "d": "Euro BTP"
              },
              {
                "s": "EUREX:FGBM1!",
                "d": "Euro BOBL"
              }
            ],
            "originalTitle": "Bonds"
          },
          {
            "title": "Forex",
            "symbols": [
              {
                "s": "FX:EURUSD",
                "d": "EUR/USD"
              },
              {
                "s": "FX:GBPUSD",
                "d": "GBP/USD"
              },
              {
                "s": "FX:USDJPY",
                "d": "USD/JPY"
              },
              {
                "s": "FX:USDCHF",
                "d": "USD/CHF"
              },
              {
                "s": "FX:AUDUSD",
                "d": "AUD/USD"
              },
              {
                "s": "FX:USDCAD",
                "d": "USD/CAD"
              }
            ],
            "originalTitle": "Forex"
          }
        ]      
    }`;
    
    useEffect(() => {
        if (moRef.current !== null) {
            moRef.current.appendChild(moScript);
        }
    }, []);


    // Active stocks
    const hotRef = createRef();
    const hotScript = document.createElement("script");
    hotScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js";
    hotScript.async = true;
    hotScript.innerHTML = `{
        "colorTheme": "light",
        "dateRange": "12M",
        "exchange": "US",
        "showChart": true,
        "locale": "en",
        "largeChartUrl": "",
        "isTransparent": false,
        "showSymbolLogo": false,
        "showFloatingTooltip": false,
        "width": "100%",
        "height": "600",
        "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
        "plotLineColorFalling": "rgba(41, 98, 255, 1)",
        "gridLineColor": "rgba(240, 243, 250, 0)",
        "scaleFontColor": "rgba(120, 123, 134, 1)",
        "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
        "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
        "symbolActiveColor": "rgba(41, 98, 255, 0.12)"      
    }`;
    useEffect( () => {
      if (hotRef.current) {
        hotRef.current.appendChild(hotScript);
      }
    }, []);


    // calendar
    const calRef = createRef();
    const calScript = document.createElement("script");
    calScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
    calScript.async = true;
    calScript.innerHTML = `{
        "colorTheme": "light",
        "isTransparent": false,
        "width": "100%",
        "height": "500",
        "locale": "en",
        "importanceFilter": "0,1"      
    }`;

    useEffect(() => {
        if (calRef.current !== null) {
            calRef.current.appendChild(calScript);
        }
    }, []);

    
    // crypto
    const cryptoRef = createRef();    
    const cryptoScript = document.createElement("script");
    cryptoScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    cryptoScript.async = true;
    cryptoScript.innerHTML = `
    {
      "width": "100%",
      "height": "600",
      "defaultColumn": "overview",
      "screener_type": "crypto_mkt",
      "displayCurrency": "USD",
      "colorTheme": "light",
      "locale": "en"
    }
    `;
    
    useEffect(() => {
        if (cryptoRef.current !== null) {
            cryptoRef.current.appendChild(cryptoScript);
        }
    }, []);

    // snaps
    // const newsRef = createRef();
    // const newsScript = document.createElement("script");
    // newsScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    // newsScript.async = true;
    // newsScript.innerHTML = `
    // {
    //   "feedMode": "all_symbols",
    //   "colorTheme": "light",
    //   "isTransparent": false,
    //   "displayMode": "regular",
    //   "width": "100%",
    //   "height": "100%",
    //   "locale": "en"
    // }
    // `;
    
    // const newsRef = createRef();
    // const newsScript = document.createElement("script");
    // newsScript.src = "https://apps.elfsight.com/p/platform.js";
    // newsScript.async = true;
    // newsScript.innerHTML = `{
    //   "height": "1000"
    // }`;
    
    // useEffect(() => {
    //     if (newsRef.current !== null) {
    //       newsRef.current.appendChild(newsScript);
    //     }
    // }, []);


    // setting up height
    const [finalHeight, setFinalHeight] = useState(false)
    const leftStackRef = useRef();
    const leftStackHeight = useContainerDimensions(leftStackRef).height;
    // console.log(leftStackHeight);



    
    return (
      <div>
      <Stack direction="row" spacing={0} sx={{
            justifyContent: "space-between",
          }}>
            <Stack direction="column" ref={leftStackRef} spacing={2} sx={{
              width: "70%",
              backgroundColor: '#f5f5f5',
              paddingRight: '40px',
            }}>
              <Paper>
              <div style={{
                  height: '100px',
                  backgroundColor: '#f5f5f5',
                  padding: '10px',
                  display: 'flex', 
                  justifyContent: 'center'
              }}>
                  <Typography variant="h3" component="h3">
                      Market Overview
                  </Typography>
              </div>
              <div ref={moRef}></div>
              </Paper>
              <Paper>
              <div style={{
                  height: '100px',
                  backgroundColor: '#f5f5f5',
                  padding: '10px',
                  display: 'flex', 
                  justifyContent: 'center'
              }}>
                  <Typography variant="h3" component="h3">
                      Hot Lists
                  </Typography>
              </div>
              <div ref={hotRef}></div>
              </Paper>
              <Paper>
              <div style={{
                  height: '100px',
                  // marginTop: '50px',
                  backgroundColor: '#f5f5f5',
                  padding: '10px',
                  display: 'flex', 
                  justifyContent: 'center'
              }}>
                  <Typography variant="h3" component="h3">
                      Economic Calendar
                  </Typography>
              </div>
              <div ref={calRef} style={{marginTop: '50px'}}></div>
              </Paper>
              <Paper>
              <div style={{
                  height: '100px',
                  backgroundColor: '#f5f5f5',
                  padding: '10px',
                  display: 'flex', 
                  justifyContent: 'center'
              }}>
                  <Typography variant="h3" component="h3">
                      Crypto Market
                  </Typography>
              </div>
              <div ref={cryptoRef}></div>
              </Paper>
            </Stack>
            <div style={{
              height: Math.max(leftStackHeight, 2800),
              width: '30%',
              overflowY: 'auto',
              overflowX: 'hidden',
              background: '#f5f5f5'
            }}>
              <NewsFeed />
            </div>
            {/* <div ref={newsRef} style={{
              height: leftStackHeight,
              width: '30%',
              background: '#f5f5f5'
            }}>
            <div class="elfsight-app-efb56b10-ab97-435b-b340-9655b1b61b88"></div>
            </div> */}

      </Stack>
      </div>
    );
}

export default MarketSummary;
