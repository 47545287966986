
import React,  { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { connect } from 'react-redux';

// custom utils
import { DrawChart } from "../charts/TVChart";
import { TopBar } from "../bars/CryptoWatchBars";
import { useWindowDimensions } from "../pages/Utils";

// local json files
import coins from "../data/coins.json";

// these two coins are not supported by tradingview
const dropList = ['MIOTAUSD', 'BORAUSD']
const coinList = coins.slice(0, 110).filter(coin => coin.Stablecoin === false).map(coin => coin.Ticker).filter(coin => !dropList.includes(coin));

const CryptoWatch = ({ navbarSize, topbarSize }) => {

    // *** TODO: there is a potential to group cryptos and list them here ***
    const [groupList, setGroupList] = useState([]);

    const handleWatchlist = () => {
        console.log('this is a placeholer const.')
    }

    // setting interval
    const [interval, setInterval] = useState('D');
    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
        // handleFocus();
    }

    // setting theme
    const [darkMode, setDarkMode] = useState(true);
    const handleThemeChange = () => {
        setDarkMode(!darkMode);
        // console.log(e);
    }
    
    // get interval from local storage, save interval to local storage
    useEffect(() => {
        let localInterval = JSON.parse(localStorage.getItem('user-interval'));
        if (localInterval) {
            setInterval(localInterval);
        }

        let localTheme = JSON.parse(localStorage.getItem('dark-theme'));
        if (localTheme !== null) {
            setDarkMode(localTheme);
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('user-interval', JSON.stringify(interval));
        localStorage.setItem('dark-theme', JSON.stringify(darkMode));
    });

    // setting chart dimensions *** potential to improve ***
    const topHeight = navbarSize + topbarSize + 10
    const chartWidth = useWindowDimensions().width;
    const chartHeight = useWindowDimensions().height;


    return (
        <Stack>
        <TopBar
            interval={ interval }
            darkMode={ darkMode }
            handleIntervalChange={ handleIntervalChange }
            handleWatchlist={ handleWatchlist }
            handleThemeChange={ handleThemeChange }
        >
        </TopBar>
        
        <Grid container sx={{
            minHeight: chartHeight - topHeight,
            backgroundColor: '#131722',
        }}>
            <Grid item xs={12}>
                <DrawChart
                width={ chartWidth }
                height={ chartHeight - topHeight }
                symbol={ coinList[0] }
                watchlist={ coinList }
                interval={ interval }
                darkMode={ darkMode }
                ></DrawChart>
            </Grid>
        </Grid>
        </Stack>
    )
}

const mapStateToProps = (state) => {
    return {
        navbarSize: state.barSize.navbarSize,
        topbarSize: state.barSize.topbarSize,
    }
}
  
export default connect( mapStateToProps ) (CryptoWatch);
