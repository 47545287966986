import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    LOADING_WATCHLIST_SUCCESS,
    LOADING_WATCHLIST_FAIL,
    LOADING_PUBLIC_KEY_SUCCESS,
    LOADING_PUBLIC_KEY_FAIL,
} from '../actions/types';

const initialState = {
    access_token: localStorage.getItem('access_token'),
    refresh_token: localStorage.getItem('refresh_token'),
    isAuthenticated: localStorage.getItem('access_token') ? true : false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    watchlistData: localStorage.getItem('watchlist-data') ? JSON.parse(localStorage.getItem('watchlist-data'))['data'] : [],
    publicKey: localStorage.getItem('public-key') ? JSON.parse(localStorage.getItem('public-key')) : null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access_token', payload.access_token);
            localStorage.setItem('refresh_token', payload.refresh_token);
            localStorage.setItem('user', JSON.stringify(payload.user));
            return {
                ...state,
                isAuthenticated: true,
                access_token: payload.access_token,
                refresh_token: payload.refresh_token,
            }
        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false
            }
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            return {
                ...state,
                access_token: null,
                refresh_token: null,
                isAuthenticated: false,
                user: null
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload
            }
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null
            }
        case LOADING_WATCHLIST_SUCCESS:
            localStorage.setItem('watchlist-data', JSON.stringify({'data': payload}));
            return {
                ...state,
                watchlistData: payload,
            }
        case LOADING_WATCHLIST_FAIL:
            return {
                ...state,
            }
        case LOADING_PUBLIC_KEY_SUCCESS:
            localStorage.setItem('public-key', JSON.stringify(payload));
            return {
                ...state,
                publicKey: payload,
            }
        case LOADING_PUBLIC_KEY_FAIL:
            return {
                ...state,
            }
            
        default:
            return state
    }
}